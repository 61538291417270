import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const videoMessages = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="video-messages-content"
            id="video-messages-header"
        >
            <Typography variant="h6">Video Messages</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                List Video Messages
            </Typography>
            <CodeBlock code={`POST /api/videoMessages?tenantId=your_tenant_id
{
    "page": 0,                       // Required: Page number (0-based)
    "pageSize": 10,                  // Required: Number of items per page
    "sortColumn": "id",              // Required: Column to sort by
    "sortDirection": "desc"          // Required: Sort direction ("asc" or "desc")
}

// Response
{
    "count": 150,         // Total number of messages matching criteria
    "rows": [
        {
            "id": 1,
            "createdAt": "2024-03-14T10:30:00.000Z",     // Timestamp of the message
            "cloudinaryUrl_Preview": "https://...",       // URL to the video thumbnail
            "isUnread": true,                            // Whether the message has been viewed
            "ResponderAlias": {                          // Responder who sent the message
                "Alias": "John"
            }
        }
        // ... more message entries
    ]
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Get Video Message
            </Typography>
            <CodeBlock code={`GET /api/videoMessages/{id}?tenantId=your_tenant_id

// Response
{
    "cloudinaryUrl_Video": "https://..."    // URL to the video file
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Mark Message as Read
            </Typography>
            <CodeBlock code={`POST /api/videoMessage-read
{
    "id": 1,                    // Required: Message ID
    "tenantId": "your_tenant_id"
}

// Response: 200 OK on successful update`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Delete Video Message
            </Typography>
            <CodeBlock code={`DELETE /api/videomessages/{id}?tenantId=your_tenant_id

// Response: 200 OK on successful deletion`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: The video messages endpoint supports server-side pagination and sorting. Videos and previews are stored and served via Cloudinary URLs. Unread status is tracked and can be updated via the videoMessage-read endpoint.
            </Typography>
        </AccordionDetails>
    </Accordion>
); 