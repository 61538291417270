import React from 'react';
import Navigation from '../Navigation';

function Layout({ children, title, onNavigate }) {
    return (
        <Navigation title={title} onNavigate={onNavigate}>
            {children}
        </Navigation>
    );
}

export default Layout;
