import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { TextField, Button, Checkbox, Autocomplete, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, Container, Grid, Box, Alert, AlertTitle, Snackbar, Tooltip, Typography, useTheme, useMediaQuery, Card, CardContent, Stack, Accordion, AccordionSummary, AccordionDetails, Chip } from '@mui/material';
import { AccessTime, CalendarMonth, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';

const NumberPlatesGrid = () => {
    const [rows, setRows] = useState([]);
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [loadingAddOrUpdate, setLoadingAddOrUpdate] = useState(false);
    const [deletingIds, setDeletingIds] = useState(new Set());
    const [gridLoading, setGridLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { globalTenantId } = useContext(AuthContext);
    const [sharePlates, setSharePlates] = useState(false);
    const [sharingMasterTenantID, setSharingMasterTenantID] = useState(null);
    const [sharingMasterTenantName, setSharingMasterTenantName] = useState('');
    const [responderAliases, setResponderAliases] = useState([]);
    const [showAliases, setShowAliases] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [allUsers, setAllUsers] = useState([]); // To hold all available users
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [enableDateRestriction, setEnableDateRestriction] = useState(false);
    const [enableTimeRestriction, setEnableTimeRestriction] = useState(false);
    const [restrictionsExpanded, setRestrictionsExpanded] = useState(false);
    const [verificationExpanded, setVerificationExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [anprEnabled, setAnprEnabled] = useState(null);
    const [showAccessWarning, setShowAccessWarning] = useState(false);
    const [accessWarningMessage, setAccessWarningMessage] = useState('');

    useEffect(() => {
        if (!globalTenantId) return;   
        fetchData();
        fetchResponderAliases(); 
        fetchUsers(); // Fetch users
        fetchAnprStatus(); // Add this new function call

    }, [globalTenantId]);
    
    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const calculatedHeight = viewportHeight - 300;
        
        setGridHeight(calculatedHeight);
    }, []); 
    
    // State for form inputs
    const [form, setForm] = useState({
        plate: '', name: '', responderAliasId: null, mobileConfirmationNumber: '', userIdConfirmation: null, internalName: '', startDate: '', endDate: '', timeOfDayStart: '', timeOfDayEnd: ''
    });
    
    const fetchResponderAliases = () => {

        axios.get(`/api/responder-aliases-isenabled`,  { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(response => {
            if (response.data.SettingAllowAliasesForNumberPlates === true) {
                setShowAliases(true);
            }
            else {
                setShowAliases(false);
            }
        })

        axios.get('/api/responder-aliases-kiosk',  { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(response => {
            setResponderAliases(response.data);
        
        })
        .catch(error => console.error('Error fetching responder aliases:', error));
    };

    const fetchUsers = () => {
        axios.get('/api/users', { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(result => {
          setAllUsers(result.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
      };

    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value,
        }));
    };

    // Create a copy of the form data for submission
    const prepareSubmitData = (formData) => {
        return {
            ...formData,
            timeOfDayStart: enableTimeRestriction ? formData.timeOfDayStart : null,
            timeOfDayEnd: enableTimeRestriction ? formData.timeOfDayEnd : null,
            startDate: enableDateRestriction ? (formData.startDate || null) : null,
            endDate: enableDateRestriction ? (formData.endDate || null) : null
        };
    };

    // Function to handle form submission
    const handleAddSubmit = (e) => {
        e.preventDefault();
        if (!form.plate.trim()) {
            setErrorMessage('Plate is required');
            setShowAlert(true);
            return;
        }
        if (!form.name.trim()) {
            setErrorMessage('Welcome Name is required');
            setShowAlert(true);
            return;
        }

        // Validate date restrictions
        if (enableDateRestriction && !form.startDate && !form.endDate) {
            setErrorMessage('When date restriction is enabled, you must specify at least a start date or end date');
            setShowAlert(true);
            return;
        }

        // Validate time restrictions
        if (enableTimeRestriction && (!form.timeOfDayStart || !form.timeOfDayEnd)) {
            setErrorMessage('When time restriction is enabled, both start and end times are required');
            setShowAlert(true);
            return;
        }

        const submitData = prepareSubmitData(form);

        setLoadingAddOrUpdate(true);
        axios.post('/api/number-plates', submitData, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
            .then(result => {
                setDialogOpen(false);
                setLoadingAddOrUpdate(false);
                resetForm();
                fetchData();
            })
            .catch(error => {
                setLoadingAddOrUpdate(false);
                setErrorMessage(error.response.data);
                setShowAlert(true);
            });
    };

    const fetchData = () => {
        setGridLoading(true);
        setShowAccessWarning(false); // Reset warning state

        // get the share plates setting
        axios.get('/api/number-plates/is-shared', { params: { tenantId: globalTenantId } })
        .then(result => {
            let shareId = null;
            setSharePlates(result.data.SharePlates);
            if (result.data.SharePlates) {
                shareId = result.data.SharingPlatesMasterTenantID; 
                setSharingMasterTenantID(shareId);
                setSharingMasterTenantName(result.data.masterTenantName);
            }
            else {
                setSharingMasterTenantID(null);
            }
            // get the plates either from the shared tenant or the global one if sharing is not enabled
            axios.get('/api/number-plates', { params: { tenantId: shareId || globalTenantId } })
            .then(result => {
                let data = result.data;
                setGridLoading(false);
                setRows(data);
            })
            .catch(error => {
                setGridLoading(false);
                if (error.response?.data?.includes('Not allowed to access this tenant')) {
                    setShowAccessWarning(true);
                    setAccessWarningMessage(`This tenant has shared number plates from another tenant to which you don't have access. Please contact your administrator.`);
                    setRows([]); // Clear any existing data
                }
                console.error('Error fetching data:', error);
            });
        })
        .catch(error => {
            setGridLoading(false);
            console.error('Error fetching data:', error);
        });
    };

    // State for editing mode and edited entry
    const [isEditing, setIsEditing] = useState(false);
    const [editedEntry, setEditedEntry] = useState(null);

    // Function to handle editing
    const handleEditClick = (entry) => {
        // Format dates from ISO string to YYYY-MM-DD format
        const formatDate = (dateString) => {
            if (!dateString) return '';
            return new Date(dateString).toISOString().split('T')[0];
        };

        // Format times from database format to HH:mm format
        const formatTime = (timeString) => {
            if (!timeString) return '';
            // Extract hours and minutes from the time string
            const match = timeString.match(/(\d{1,2}):(\d{2})/);
            if (!match) return '';
            
            // Pad hours with leading zero if needed
            const hours = match[1].padStart(2, '0');
            const minutes = match[2];
            
            return `${hours}:${minutes}`;
        };

        setForm({ 
            plate: entry.plate, 
            name: entry.name, 
            responderAliasId: entry.responderAliasId,
            mobileConfirmationNumber: entry.mobileConfirmationNumber, 
            userIdConfirmation: entry.userIdConfirmation, 
            internalName: entry.internalName,
            startDate: formatDate(entry.startDate),
            endDate: formatDate(entry.endDate),
            timeOfDayStart: formatTime(entry.timeOfDayStart),
            timeOfDayEnd: formatTime(entry.timeOfDayEnd)
        });

        // Set the checkboxes based on whether dates/times exist
        setEnableDateRestriction(!!(entry.startDate || entry.endDate));
        setEnableTimeRestriction(!!(entry.timeOfDayStart || entry.timeOfDayEnd));
        
        // Set expanded states
        setRestrictionsExpanded(!!(entry.startDate || entry.endDate || entry.timeOfDayStart || entry.timeOfDayEnd));
        setVerificationExpanded(!!(entry.userIdConfirmation || entry.mobileConfirmationNumber));
        
        setEditedEntry(entry);
        setIsEditing(true);
        setDialogOpen(true);
    };

    // Function to handle cancel action
    const handleCancelEdit = () => {
        setDialogOpen(false);
        resetForm();// Reset the form to its initial state
        setIsEditing(false); // Exit editing mode
        setEditedEntry(null); // Clear edited entry
    };
    
    // Function to handle update submission
    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        if (!form.plate.trim()) {
            setErrorMessage('Plate is required');
            setShowAlert(true);
            return;
        }
        if (!form.name.trim()) {
            setErrorMessage('Welcome Name is required');
            setShowAlert(true);
            return;
        }

        // Validate date restrictions
        if (enableDateRestriction && !form.startDate && !form.endDate) {
            setErrorMessage('When date restriction is enabled, you must specify at least a start date or end date');
            setShowAlert(true);
            return;
        }

        // Validate time restrictions
        if (enableTimeRestriction && (!form.timeOfDayStart || !form.timeOfDayEnd)) {
            setErrorMessage('When time restriction is enabled, both start and end times are required');
            setShowAlert(true);
            return;
        }

        const submitData = prepareSubmitData(form);

        setLoadingAddOrUpdate(true);
        axios.put(`/api/number-plates/${editedEntry.id}`, submitData, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
            .then(result => {
                setDialogOpen(false);
                setLoadingAddOrUpdate(false);
                resetForm();
                setIsEditing(false);
                setEditedEntry(null);
                fetchData();
            })
            .catch(error => {
                setLoadingAddOrUpdate(false);
                setErrorMessage(error.response.data);
                setShowAlert(true);
            });
    };

    const handleSharePlateChange = (event) => {
        setSharePlates(event.target.checked);

        // update the share plates setting
        axios.post(`/api/number-plates/is-shared?tenantId=${globalTenantId}`, { SharePlates: event.target.checked })
        .then(result=> {
            // do nothing
            fetchData();
        })
        .catch(error => {
            console.error('Error updating share plates setting:', error);
        });
    };

    const handleDelete = (id) => {
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/number-plates/${id}`, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Reset the form and editing mode
            resetForm();
            setIsEditing(false);
            setEditedEntry(null);
            // Refresh the list of number plates
            fetchData();
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            console.error('Error deleting number plate:', error)
        });
    };

    function resetForm()
    {
        setForm({ 
            plate: '', 
            name: '', 
            responderAliasId: null, 
            mobileConfirmationNumber: '', 
            userIdConfirmation: null, 
            internalName: '',
            startDate: '',
            endDate: '',
            timeOfDayStart: '',
            timeOfDayEnd: ''
        });
        setEnableDateRestriction(false);
        setEnableTimeRestriction(false);
        setRestrictionsExpanded(false);
        setVerificationExpanded(false);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        resetForm();
    };

    const handleDialogOpen = async () => {
        setDialogOpen(true);
    };

    const getRestrictionChip = (row) => {
        const hasTimeRestriction = row.timeOfDayStart && row.timeOfDayEnd;
        const hasDateRestriction = row.startDate || row.endDate;
        
        if (!hasTimeRestriction && !hasDateRestriction) return null;

        // Check if plate is inactive due to dates
        const isInactive = row.startDate || row.endDate ? !isPlateActive(row.startDate, row.endDate) : false;

        let label = [];
        if (hasDateRestriction) label.push('Date');
        if (hasTimeRestriction) label.push('Time');
        
        // Extract time from UTC string (format: HH:mm)
        const formatTime = (timeString) => timeString.split('T')[1].substring(0, 5);
        
        // Build detailed tooltip text
        let tooltipText = isInactive 
            ? "This plate is currently inactive due to date restrictions"
            : "This plate is currently active with the following restrictions:";
        
        if (hasDateRestriction) {
            tooltipText += `\nDates: ${row.startDate ? `from ${new Date(row.startDate).toLocaleDateString()}` : 'no start date'} ${row.endDate ? `until ${new Date(row.endDate).toLocaleDateString()}` : 'no end date'}`;
        }
        if (hasTimeRestriction) {
            tooltipText += `\nTime: ${formatTime(row.timeOfDayStart)} to ${formatTime(row.timeOfDayEnd)} daily`;
        }

        return (
            <Tooltip title={tooltipText}>
                <Chip
                    icon={<AccessTime />}
                    label={`${label.join(' & ')} Restricted${isInactive ? ' (Inactive)' : ''}`}
                    color={isInactive ? "error" : "success"}
                    size="small"
                    variant="outlined"
                />
            </Tooltip>
        );
    };

    const columns = [
        // 100% of available space

        { field: "plate", headerName: "Plate", flex: 1},
        { field: "name", headerName: "Welcome Name", flex: 1},
        { field: "internalName", headerName: "Internal Name", flex: 1},
        { field: "mobileConfirmationNumber", headerName: "Mobile Confirm", flex: 1},
        { field: "userIdConfirmation", headerName: "User Confirm", flex: 1, 
            valueGetter: (params) => {
                const user = allUsers.find(user => user.id === params.row.userIdConfirmation);
                return user ? `${user.first_name} ${user.last_name}` : '';
            }},
        ...(showAliases ? [{
            field: "responderAlias", 
            headerName: "Alias", 
            flex: 1,
            valueGetter: (params) => {
                const alias = responderAliases.find(alias => alias.id === params.row.responderAliasId);
                return alias ? alias.alias : '';
            }
        }] : []),
        ,
        {
            field: 'restrictions',
            headerName: 'Restrictions',
            width: 260,
            renderCell: (params) => getRestrictionChip(params.row)
        },
        {
            field: "edit",
            headerName: "",
            width: 80,
            renderCell: (params) => (
                <Button onClick={() => handleEditClick(params.row)} variant="contained" color="primary">
                    Edit
                </Button>
            )
        },
        { field: 'delete', headerName: '', width: 80,
        renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id)}
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
        ),
        }
    ];

    // Add this function to filter rows
    const filteredRows = rows.filter(row => {
        const searchLower = searchTerm.toLowerCase();
        return (
            row.plate.toLowerCase().includes(searchLower) ||
            row.name.toLowerCase().includes(searchLower) ||
            (row.internalName && row.internalName.toLowerCase().includes(searchLower))
        );
    });

    const isPlateActive = (startDate, endDate) => {
        if (!startDate && !endDate) return true;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        if (startDate && new Date(startDate) > today) return false;
        if (endDate) {
            const endOfDay = new Date(endDate);
            endOfDay.setHours(23, 59, 59, 999);
            if (today > endOfDay) return false;
        }
        return true;
    };
    
    // Mobile view component
    const MobileView = ({ rows }) => (
        <Stack spacing={2}>
            {rows.map((row) => (
                <Card key={row.id}>
                    <CardContent>
                        {/* Header */}
                        <Typography variant="h6" gutterBottom>
                            {row.plate}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {row.name}
                        </Typography>

                        {/* Details */}
                        {row.internalName && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Internal: {row.internalName}
                            </Typography>
                        )}
                        {showAliases && row.responderAliasId && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Alias: {responderAliases.find(alias => alias.id === row.responderAliasId)?.alias}
                            </Typography>
                        )}
                        {row.mobileConfirmationNumber && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Mobile: {row.mobileConfirmationNumber}
                            </Typography>
                        )}
                        {row.userIdConfirmation && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                User: {allUsers.find(user => user.id === row.userIdConfirmation)?.first_name} {allUsers.find(user => user.id === row.userIdConfirmation)?.last_name}
                            </Typography>
                        )}

                        {/* Restrictions */}
                        {(row.startDate || row.endDate || row.timeOfDayStart || row.timeOfDayEnd) && (
                            <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                                {(row.startDate || row.endDate) && (
                                    <Tooltip title="Has date restrictions">
                                        <Chip 
                                            icon={<CalendarMonth />} 
                                            size="small" 
                                            label="Date"
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </Tooltip>
                                )}
                                {(row.timeOfDayStart || row.timeOfDayEnd) && (
                                    <Tooltip title="Has time restrictions">
                                        <Chip 
                                            icon={<AccessTime />} 
                                            size="small" 
                                            label="Time"
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </Tooltip>
                                )}
                            </Stack>
                        )}

                        {/* Actions */}
                        <Stack direction="row" spacing={1}>
                            <Button 
                                onClick={() => handleEditClick(row)} 
                                variant="contained" 
                                color="primary"
                                size="small"
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={() => handleDelete(row.id)}
                                variant="outlined"
                                color="secondary"
                                disabled={deletingIds.has(row.id)}
                                size="small"
                            >
                                {deletingIds.has(row.id) ? <CircularProgress size={24} /> : 'Delete'}
                            </Button>
                        </Stack>

                        {getRestrictionChip(row) && (
                            <Box sx={{ mt: 1 }}>
                                {getRestrictionChip(row)}
                            </Box>
                        )}
                    </CardContent>
                </Card>
            ))}
        </Stack>
    );

    const fetchAnprStatus = () => {
        axios.get('/api/tenants-anpr-status', { 
            params: { tenantId: globalTenantId } // Always use global tenant ID for status
        })
        .then(response => {
            setAnprEnabled(response.data.SettingEnableANPR);
        })
        .catch(error => console.error('Error fetching ANPR status:', error));
    };

    return (
        <Layout title='Number Plates for ANPR'>   
            {anprEnabled === false && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    <AlertTitle>ANPR is currently disabled</AlertTitle>
                    {sharingMasterTenantID && globalTenantId !== sharingMasterTenantID ? (
                        `ANPR is disabled on this kiosk. The number plates shared with ${sharingMasterTenantName} will not be used.`
                    ) : (
                        "ANPR is disabled for this kiosk. Only kiosks with ANPR enabled can use the listed number plates."
                    )}
                </Alert>
            )}

            {showAccessWarning && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    <AlertTitle>Access Restricted</AlertTitle>
                    {accessWarningMessage}
                </Alert>
            )}

            <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                    <Tooltip title={
                        <Typography>
                            When this option is selected, the plates listed on this screen is the master list. All other devices then use these plates. 
                            {sharePlates && globalTenantId != sharingMasterTenantID ? 'This option is disabled because this is not the device sharing number plates.' : ''}
                        </Typography>
                    }>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sharePlates}
                                    onChange={handleSharePlateChange}
                                    name="sharePlates"
                                    color="primary"
                                    disabled={sharePlates && globalTenantId != sharingMasterTenantID}
                                />
                            }
                            label={'Share plates across all devices ' + 
                            (sharePlates && globalTenantId != sharingMasterTenantID ? `(plates are shared with ${sharingMasterTenantName})` : '')}
                        />
                    </Tooltip>                
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button 
                        onClick={() => handleDialogOpen(null)} 
                        variant="contained" 
                        color="primary"
                        fullWidth
                        disabled={showAccessWarning}
                    >
                        Add Number Plate
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Search plates, names..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                </Grid>
            </Grid>

            {/* Form to add number plate */}
            <Dialog 
                open={dialogOpen} 
                onClose={handleDialogClose} 
                aria-labelledby="form-dialog-title" 
                maxWidth='md'
                fullWidth
            >
                <form onSubmit={isEditing ? handleUpdateSubmit : handleAddSubmit}>
                    <DialogTitle id="form-dialog-title">
                        {isEditing ? 'Edit Number Plate' : 'Add Number Plate'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {/* Left Column - Basic Information */}
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Tooltip title="The vehicle number plate">
                                            <TextField
                                                fullWidth
                                                required
                                                label="Plate"
                                                name="plate"
                                                value={form.plate}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                sx={{ mt: 1 }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tooltip title="A friendly name that will show on the kiosk on successful entry after 'Welcome...'">
                                            <TextField
                                                fullWidth
                                                required
                                                label="Welcome name shown on kiosk"
                                                name="name"
                                                value={form.name}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tooltip title="Your own name for this number plate">
                                            <TextField
                                                fullWidth
                                                label="Internal name"
                                                name="internalName"
                                                value={form.internalName}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                            />
                                        </Tooltip>
                                    </Grid>

                                    { showAliases && (
                                        <Grid item xs={12}>
                                            <Tooltip title="Specify which alias this number plate will notify on successful entry.">
                                                <Autocomplete
                                                    options={responderAliases}
                                                    getOptionLabel={(option) => option.alias} 
                                                    value={responderAliases.find(alias => alias.id === form.responderAliasId) || null}
                                                    onChange={(event, newValue) => {
                                                        setForm(prevForm => ({
                                                            ...prevForm,
                                                            responderAliasId: newValue ? newValue.id : null 
                                                        }));
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Responder Alias"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            {/* Right Column - Accordions */}
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Accordion 
                                            expanded={verificationExpanded}
                                            onChange={(e, isExpanded) => setVerificationExpanded(isExpanded)}
                                        >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>2-Step Verification</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Tooltip title="You may choose to secure automatic ANPR gate opens with a second step verification. This cannot be combined with mobile-based ANPR authorisation. By selecting a user, they will receive a push notification to their Entrinsic Connect app. The gate will open only after positively confirming.">
                                                            <Autocomplete
                                                                id="users-autocomplete"
                                                                options={allUsers}
                                                                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                                                value={allUsers.find(user => user.id === form.userIdConfirmation) || null}
                                                                onChange={(event, newValue) => {
                                                                    setForm(prevForm => ({
                                                                        ...prevForm,
                                                                        userIdConfirmation: newValue ? newValue.id : null 
                                                                    }));
                                                                }}
                                                                disabled={form.mobileConfirmationNumber != '' && form.mobileConfirmationNumber != null}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        label="User"
                                                                    />
                                                                )}
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Tooltip title="You may choose to secure automatic ANPR gate opens with a second step verification. This cannot be combined with user-based ANPR authorisation. By specifying a mobile number, the user will be sent a text message to positively confirm the gate open.">
                                                            <TextField
                                                                fullWidth
                                                                label="Mobile"
                                                                name="mobileConfirmationNumber"
                                                                value={form.mobileConfirmationNumber}
                                                                onChange={handleInputChange}
                                                                variant="outlined"
                                                                disabled={form.userIdConfirmation != null}
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Accordion 
                                            expanded={restrictionsExpanded}
                                            onChange={(e, isExpanded) => setRestrictionsExpanded(isExpanded)}
                                        >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>Date & Time Access Restrictions</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={enableDateRestriction}
                                                                    onChange={(e) => {
                                                                        setEnableDateRestriction(e.target.checked);
                                                                        if (!e.target.checked) {
                                                                            setForm(prev => ({
                                                                                ...prev,
                                                                                startDate: '',
                                                                                endDate: ''
                                                                            }));
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label="Enable Date Restriction"
                                                        />
                                                    </Grid>
                                                    
                                                    {enableDateRestriction && (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <Tooltip title="Optional. If not set, there is no start date restriction. If set, the plate will only be active from this date onwards.">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Start Date"
                                                                            type="date"
                                                                            name="startDate"
                                                                            value={form.startDate}
                                                                            onChange={handleInputChange}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Tooltip title="Optional. If not set, there is no end date restriction. If set, the plate will become inactive after this date.">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="End Date"
                                                                            type="date"
                                                                            name="endDate"
                                                                            value={form.endDate}
                                                                            onChange={handleInputChange}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={enableTimeRestriction}
                                                                    onChange={(e) => {
                                                                        setEnableTimeRestriction(e.target.checked);
                                                                        if (!e.target.checked) {
                                                                            setForm(prev => ({
                                                                                ...prev,
                                                                                timeOfDayStart: '',
                                                                                timeOfDayEnd: ''
                                                                            }));
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label="Enable Time Restriction"
                                                        />
                                                    </Grid>

                                                    {enableTimeRestriction && (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <Tooltip title="The time of day from which the plate becomes active. The plate will not work before this time.">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Start Time"
                                                                            type="time"
                                                                            name="timeOfDayStart"
                                                                            value={form.timeOfDayStart}
                                                                            onChange={handleInputChange}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Tooltip title="The time of day after which the plate becomes inactive. The plate will not work after this time.">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="End Time"
                                                                            type="time"
                                                                            name="timeOfDayEnd"
                                                                            value={form.timeOfDayEnd}
                                                                            onChange={handleInputChange}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelEdit} color="primary" variant='contained'>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant='contained'>
                            {isEditing ? 
                                ( loadingAddOrUpdate ? <CircularProgress size={24} /> : 'Update') : 
                                ( loadingAddOrUpdate ? <CircularProgress size={24} /> : 'Add')
                            }
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {showAlert && (
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={showAlert}
                autoHideDuration={10000}
                message={errorMessage}
                onClose={() => setShowAlert(false)}
                >
                    <Alert severity="error" onClose={() => setShowAlert(false)}>
                    {errorMessage}
                    </Alert>
                </Snackbar>
            )}
            <Box sx={{ mt: 4 }}>
                {isMobile ? (
                    <MobileView rows={filteredRows} />
                ) : (
                    <Box sx={{ height: gridHeight }}>
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            pageSize={100}
                            loading={gridLoading}
                            disableSelectionOnClick
                        />
                    </Box>
                )}
            </Box>

        </Layout>
    );
}

export default NumberPlatesGrid;
