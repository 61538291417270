import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const kioskMessages = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="kiosk-messages-content"
            id="kiosk-messages-header"
        >
            <Typography variant="h6">Kiosk Messages</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="body2" paragraph>
                The Kiosk Messages API allows you to display temporary messages on kiosks. When a message is sent to a kiosk, 
                it will be shown for a few seconds and automatically mirrored to all other kiosks where mirroring is enabled. 
                This is particularly useful for providing real-time feedback to visitors based on AI analysis or other automated systems.
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Send Message to Kiosk
            </Typography>
            <CodeBlock code={`POST /api/send-kiosk-message
{
    "tenantId": "number",  // The ID of the tenant
    "message": "string"    // The message to display on the kiosk
}

// Success Response
{
    "success": true
}

// Error Response
{
    "success": false,
    "message": "Error message"
}

// Error Codes
500: No tenantId provided
500: No access to this tenant
500: Message send failed - specified kiosk not connected`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Example with Movement Detection
            </Typography>
            <CodeBlock code={`// 1. Receive movement detection webhook
{
    "event": "Movement-Detection",
    "timestamp": "2024-03-14T10:30:00.000Z",
    "data": {
        "tenantId": 123,
        "imageUrl": "https://..."
    }
}

// 2. Process the image with AI
// 3. Send contextual message back to kiosk
POST /api/send-kiosk-message
{
    "tenantId": 123,
    "message": "Welcome! I notice you have a package. Please select 'Delivery' on the screen."
}

// Common Use Cases:
- Detect multiple people and ask them to approach one at a time
- Recognize packages and guide visitors to delivery options
- Identify returning visitors for personalized greetings
- Direct visitors to specific areas based on their needs`} />

        </AccordionDetails>
    </Accordion>
); 