import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Fab,
    Box,
    TextField,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Alert,
    Typography,
    Chip,
    Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

const EditDialog = ({ 
    open, 
    onClose, 
    formData, 
    setFormData, 
    eventTypes, 
    onSubmit, 
    editingWebhook 
}) => {
    const [urlError, setUrlError] = useState('');

    const validateUrl = (url) => {
        try {
            new URL(url);
            setUrlError('');
            return true;
        } catch (err) {
            setUrlError('Please enter a valid URL (e.g., https://example.com/webhook)');
            return false;
        }
    };

    const handleSubmit = () => {
        if (!validateUrl(formData.url)) {
            return;
        }
        onSubmit();
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="md"
            PaperProps={{
                sx: {
                    width: '80vw',
                    maxWidth: '1000px'
                }
            }}
            fullWidth
        >
            <DialogTitle>{editingWebhook ? 'Edit Webhook' : 'New Webhook'}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <TextField
                            label="Name"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            sx={{ flex: 1 }}
                        />
                        <TextField
                            label="URL"
                            value={formData.url}
                            onChange={(e) => {
                                setFormData({ ...formData, url: e.target.value });
                                if (e.target.value) validateUrl(e.target.value);
                            }}
                            error={!!urlError}
                            helperText={urlError}
                            sx={{ flex: 2 }}
                            required
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.active}
                                    onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                                />
                            }
                            label="Active"
                        />
                    </Box>
                    <FormGroup>
                        <Box sx={{ typography: 'subtitle1', mb: 1 }}>Choose the events that will be delivered to your hook</Box>
                        <Box 
                            sx={{ 
                                display: 'grid', 
                                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                                gap: 3,
                                fontSize: '0.875rem',
                                '& .eventColumn': {
                                    display: 'grid',
                                    gridTemplateColumns: '200px 1fr',
                                    gap: 2
                                },
                                '& .codeSection': {
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'flex-start',
                                    paddingTop: '2px'
                                },
                                '& .code': {
                                    color: 'text.secondary',
                                    fontSize: '0.875rem',
                                    fontWeight: 'bold'
                                },
                                '& .MuiTypography-root': {
                                    fontSize: '0.875rem'
                                },
                                '& .MuiCheckbox-root': {
                                    padding: '2px',
                                    marginTop: '-2px'
                                }
                            }}
                        >
                            <Box className="eventColumn">
                                {eventTypes.slice(0, Math.ceil(eventTypes.length / 2)).map((eventType) => (
                                    <React.Fragment key={eventType.id}>
                                        <Box className="codeSection">
                                            <Checkbox
                                                checked={formData.selectedEvents.includes(eventType.id)}
                                                onChange={(e) => {
                                                    const newEvents = e.target.checked
                                                        ? [...formData.selectedEvents, eventType.id]
                                                        : formData.selectedEvents.filter(id => id !== eventType.id);
                                                    setFormData({ ...formData, selectedEvents: newEvents });
                                                }}
                                            />
                                            <Typography className="code">
                                                {eventType.code}
                                            </Typography>
                                        </Box>
                                        <Typography>
                                            {eventType.description}
                                        </Typography>
                                    </React.Fragment>
                                ))}
                            </Box>
                            <Box className="eventColumn">
                                {eventTypes.slice(Math.ceil(eventTypes.length / 2)).map((eventType) => (
                                    <React.Fragment key={eventType.id}>
                                        <Box className="codeSection">
                                            <Checkbox
                                                checked={formData.selectedEvents.includes(eventType.id)}
                                                onChange={(e) => {
                                                    const newEvents = e.target.checked
                                                        ? [...formData.selectedEvents, eventType.id]
                                                        : formData.selectedEvents.filter(id => id !== eventType.id);
                                                    setFormData({ ...formData, selectedEvents: newEvents });
                                                }}
                                            />
                                            <Typography className="code">
                                                {eventType.code}
                                            </Typography>
                                        </Box>
                                        <Typography>
                                            {eventType.description}
                                        </Typography>
                                    </React.Fragment>
                                ))}
                            </Box>
                        </Box>
                    </FormGroup>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

const WebHooksDialog = ({ open, onClose }) => {
    const [webhooks, setWebhooks] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [editingWebhook, setEditingWebhook] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [error, setError] = useState('');
    const [newSecretKey, setNewSecretKey] = useState(null);
    const [showSecretDialog, setShowSecretDialog] = useState(false);
    const [logDialogOpen, setLogDialogOpen] = useState(false);
    const [selectedWebhook, setSelectedWebhook] = useState(null);
    const [logs, setLogs] = useState([]);
    const [logCount, setLogCount] = useState(0);
    const [logLoading, setLogLoading] = useState(false);
    const [logPaginationModel, setLogPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [logSortModel, setLogSortModel] = useState([
        { field: 'createdAt', sort: 'desc' }
    ]);
    
    // Form state
    const [formData, setFormData] = useState({
        name: '',
        url: '',
        secret: '',
        active: true,
        selectedEvents: []
    });

    const logColumns = [
        {
            field: "createdAt",
            headerName: "Date/Time",
            flex: 0.2,
            valueGetter: (params) => {
                return new Date(params.row.createdAt).toLocaleString();
            },
        },
        {
            field: "eventType",
            headerName: "Event",
            flex: 0.15,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.1,
            renderCell: (params) => {
                const isSuccess = params.row.statusCode >= 200 && params.row.statusCode < 300;
                return (
                    <Chip 
                        label={isSuccess ? 'success' : 'failed'}
                        color={isSuccess ? "success" : "error"}
                        size="small"
                    />
                );
            },
        },
        {
            field: "statusCode",
            headerName: "HTTP Status",
            flex: 0.1,
        },
        {
            field: "attempt",
            headerName: "Attempt",
            flex: 0.1,
        },
        {
            field: "duration",
            headerName: "Duration",
            flex: 0.1,
            valueGetter: (params) => {
                return params.value ? `${params.value}ms` : '';
            },
        },
        {
            field: "payload",
            headerName: "Payload",
            flex: 0.2,
            renderCell: (params) => {
                try {
                    const payload = params.value ? JSON.parse(params.value) : {};
                    return (
                        <Tooltip title={<pre>{JSON.stringify(payload, null, 2)}</pre>}>
                            <Typography
                                variant="body2"
                                sx={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '100%'
                                }}
                            >
                                {JSON.stringify(payload)}
                            </Typography>
                        </Tooltip>
                    );
                } catch (e) {
                    return params.value || '';
                }
            }
        },
        {
            field: "error",
            headerName: "Error",
            flex: 0.2,
            renderCell: (params) => params.value ? (
                <Tooltip title={params.value}>
                    <Typography
                        variant="body2"
                        sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            color: 'error.main'
                        }}
                    >
                        {params.value}
                    </Typography>
                </Tooltip>
            ) : null
        },
        {
            field: "responseData",
            headerName: "Response",
            flex: 0.2,
            renderCell: (params) => {
                try {
                    const response = params.value ? JSON.parse(params.value) : {};
                    return (
                        <Tooltip title={<pre>{JSON.stringify(response, null, 2)}</pre>}>
                            <Typography
                                variant="body2"
                                sx={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '100%'
                                }}
                            >
                                {JSON.stringify(response)}
                            </Typography>
                        </Tooltip>
                    );
                } catch (e) {
                    return params.value || '';
                }
            }
        }
    ];

    const fetchWebhooks = async () => {
        try {
            const response = await axios.get('/api/webhooks');
            setWebhooks(response.data);
        } catch (err) {
            setError('Failed to fetch webhooks');
        }
    };

    const fetchEventTypes = async () => {
        try {
            const response = await axios.get('/api/event-types');
            setEventTypes(response.data);
        } catch (err) {
            setError('Failed to fetch event types');
        }
    };

    const fetchLogs = async () => {
        if (!selectedWebhook?.id) return;

        setLogLoading(true);
        try {
            const result = await axios.get(`/api/webhooks/${selectedWebhook.id}/logs`, {
                params: {
                    page: logPaginationModel.page,
                    pageSize: logPaginationModel.pageSize,
                    sortColumn: logSortModel[0]?.field || 'createdAt',
                    sortDirection: logSortModel[0]?.sort || 'desc'
                }
            });

            setLogs(result.data.rows || []);
            setLogCount(result.data.count || 0);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setError('Failed to fetch webhook logs');
        } finally {
            setLogLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            fetchWebhooks();
            fetchEventTypes();
        }
    }, [open]);

    useEffect(() => {
        if (logDialogOpen && selectedWebhook) {
            fetchLogs();
        }
    }, [logDialogOpen, selectedWebhook, logPaginationModel, logSortModel]);

    const handleAddNew = () => {
        setFormData({
            name: '',
            url: '',
            secret: '',
            active: true,
            selectedEvents: []
        });
        setEditingWebhook(null);
        setShowEditDialog(true);
    };

    const handleEdit = (webhook) => {
        setFormData({
            name: webhook.name,
            url: webhook.url,
            secret: webhook.secret,
            active: webhook.active,
            selectedEvents: webhook.EventTypes.map(et => et.id)
        });
        setEditingWebhook(webhook);
        setShowEditDialog(true);
    };

    const handleDelete = async (webhookId) => {
        if (!window.confirm('Are you sure you want to delete this webhook? This will also delete all associated delivery logs.')) return;
        
        try {
            await axios.delete(`/api/webhooks/${webhookId}`);
            await fetchWebhooks();
        } catch (err) {
            setError('Failed to delete webhook');
        }
    };

    const handleSubmit = React.useCallback(async () => {
        try {
            if (editingWebhook) {
                await axios.put(`/api/webhooks/${editingWebhook.id}`, formData);
            } else {
                const response = await axios.post('/api/webhooks', formData);
                setNewSecretKey(response.data.secret);
                setShowSecretDialog(true);
            }
            setShowEditDialog(false);
            await fetchWebhooks();
        } catch (err) {
            setError('Failed to save webhook');
        }
    }, [editingWebhook, formData]);

    const handleViewLogs = (webhook) => {
        setSelectedWebhook(webhook);
        setLogDialogOpen(true);
    };

    return (
        <>
            <Dialog 
                open={open} 
                onClose={onClose}
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '90vw'
                    }
                }}
                fullWidth
            >
                <DialogTitle>Configure Web Hooks</DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="body1" paragraph>
                            Webhooks allow external applications to receive automatic notifications when specific events occur in the system.
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            • Each webhook can subscribe to multiple event types<br />
                            • Webhooks will receive POST requests with JSON payloads<br />
                            • The secret key will be shown only once after creation - save it securely<br />
                            • Each request includes an X-Signature header: sha256=HMAC(secret_key, request_body)<br />
                            • Failed webhook deliveries will be retried up to 10 times with exponential backoff
                        </Typography>
                    </Box>

                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    
                    <Box sx={{ mb: 2 }}>
                        <Fab
                            color="primary"
                            size="small"
                            onClick={handleAddNew}
                            sx={{ mt: 2 }}
                        >
                            <AddIcon />
                        </Fab>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>URL</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Last Triggered</TableCell>
                                    <TableCell>Failures</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {webhooks.map((webhook) => (
                                    <TableRow key={webhook.id}>
                                        <TableCell>{webhook.name}</TableCell>
                                        <TableCell>{webhook.url}</TableCell>
                                        <TableCell>{webhook.active ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell>{webhook.lastTriggered ? new Date(webhook.lastTriggered).toLocaleString() : 'Never'}</TableCell>
                                        <TableCell>{webhook.failureCount}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleEdit(webhook)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(webhook.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleViewLogs(webhook)}>
                                                <HistoryIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {showEditDialog && (
                <EditDialog
                    open={showEditDialog}
                    onClose={() => setShowEditDialog(false)}
                    formData={formData}
                    setFormData={setFormData}
                    eventTypes={eventTypes}
                    onSubmit={handleSubmit}
                    editingWebhook={editingWebhook}
                />
            )}

            <Dialog
                open={showSecretDialog}
                onClose={() => setShowSecretDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Save Your Webhook Secret</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                        This secret key will be used to verify webhook requests. Please save it now - you won't be able to see it again!
                    </Typography>
                    <TextField
                        value={newSecretKey || ''}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                            sx: { fontFamily: 'monospace' }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            navigator.clipboard.writeText(newSecretKey);
                        }}
                    >
                        Copy to Clipboard
                    </Button>
                    <Button 
                        onClick={() => setShowSecretDialog(false)} 
                        variant="contained"
                    >
                        I've Saved the Secret
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={logDialogOpen}
                onClose={() => setLogDialogOpen(false)}
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '90vw'
                    }
                }}
                fullWidth
            >
                <DialogTitle>
                    Webhook Delivery Logs - {selectedWebhook?.name}
                    <IconButton 
                        onClick={fetchLogs}
                        sx={{ float: 'right' }}
                        disabled={logLoading}
                    >
                        <RefreshIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={logs}
                            columns={logColumns}
                            rowCount={logCount}
                            loading={logLoading}
                            pageSizeOptions={[10, 25, 50]}
                            paginationModel={logPaginationModel}
                            paginationMode="server"
                            sortingMode="server"
                            onPaginationModelChange={setLogPaginationModel}
                            onSortModelChange={setLogSortModel}
                            sortModel={logSortModel}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLogDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WebHooksDialog;