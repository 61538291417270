import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';
export const numberPlates = (

<Accordion>
<AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="numberplates-content"
    id="numberplates-header"
>
    <Typography variant="h6">ANPR / Number Plates</Typography>
</AccordionSummary>
<AccordionDetails>
    <Typography variant="subtitle1" sx={{ mt: 3 }}>
        List Number Plates
    </Typography>
    <CodeBlock code={`GET /api/number-plates?tenantId=your_tenant_id

// Response
[
    {
        "id": 1,                                 // Unique identifier for the number plate
        "plate": "AB12 ABC",                     // Vehicle registration
        "name": "Sue",                           // Welcome name shown on kiosk
        "tenantId": 2,                           // Tenant ID
        "responderAliasId": 1,                   // Optional link to responder group
        "lastRead": "2025-01-05T11:52:53.395Z",  // Last ANPR detection
        "mobileConfirmationNumber": "",          // Mobile number for SMS 2-step verification
        "userIdConfirmation": null,              // User ID for 2-step verification
        "internalName": "Susan Boyle",           // Internal reference
        "startDate": null,                       // date restrictions
        "endDate": null,                         // date restrictions
        "timeOfDayStart": null,                  // time restrictions
        "timeOfDayEnd": null,                    // time restrictions
        "createdAt": "2024-02-27T09:06:07.384Z", // Date of creation in UTC
        "updatedAt": "2025-01-05T11:52:54.122Z", // Date of last update in UTC
        "ResponderAlias": {                      // Included if responderAliasId is set
            "alias": "Flat 7",
            "id": 1
        }
    },
    {
        "id": 2,
        "plate": "ZY23 PPP",
        "name": "Dave",
        "tenantId": 2,
        "responderAliasId": null,
        "lastRead": null,
        "mobileConfirmationNumber": null,
        "userIdConfirmation": null,
        "internalName": "Mr Dave Smith",
        "startDate": "2024-12-29T00:00:00.000Z",      // With date restrictions
        "endDate": "2024-12-29T16:46:28.000Z",
        "timeOfDayStart": "1970-01-01T13:43:00.000Z", // With time restrictions
        "timeOfDayEnd": "1970-01-01T23:45:00.000Z",
        "createdAt": "2024-05-31T13:54:33.179Z",
        "updatedAt": "2024-12-31T16:46:55.354Z",
        "ResponderAlias": null
    }
]`} />

    <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
        Note: The response is an array of all number plates. Each plate includes its full configuration including any restrictions and notification settings.
    </Typography>

    <Typography variant="subtitle1" sx={{ mt: 3 }}>
        Check if number plates are shared:
    </Typography>
    <CodeBlock code={`GET /api/number-plates/is-shared?tenantId=your_tenant_id`} />
   
    <Typography variant="subtitle1" sx={{ mt: 3 }}>
        Create Number Plate
    </Typography>
    
    <Typography variant="body2" paragraph>
        Create a plate with optional alias assignment and 2-step verification.
    </Typography>
    <CodeBlock code={`POST /api/number-plates?tenantId=your_tenant_id
{
    "plate": "ABC 123",             // Required: Vehicle registration
    "name": "John",                 // Required: Welcome name
    "internalName": "",             // Optional: Internal reference

    // Alias settings
    "responderAliasId": null,       // Optional: ID of the responder alias to notify

    // 2-step ANPR authorisation settings (mutually exclusive)
    "userIdConfirmation": null,     // Optional: User ID for 2-step verification
    "mobileConfirmationNumber": "", // Optional: Mobile number for 2-step SMS verification

    // Optional to allow the plate to be used only between these dates & times
    "startDate": "2024-12-29",      // YYYY-MM-DD
    "endDate": "2024-12-30",        // YYYY-MM-DD
    "timeOfDayStart": "13:43",      // HH:mm, required if timeOfDayEnd set
    "timeOfDayEnd": "23:45"         // HH:mm, required if timeOfDayStart set
}

// Response
{
    "id": 242,                      // Unique identifier
    "plate": "ABC 123",             // Vehicle registration as provided
    "name": "John",                 // Welcome name as provided
    "tenantId": 2,                  // Tenant ID from request
    "internalName": "",             // Internal name as provided
    "responderAliasId": null,       // Responder Alias ID as provided
    "lastRead": null,               // Initially null, updated when plate last read by a kiosk
    "mobileConfirmationNumber": "", // Mobile number as provided
    "userIdConfirmation": null,     // User ID as provided
    "startDate": "2024-12-29",      // Start date as provided
    "endDate": "2024-12-30",        // End date as provided
    "timeOfDayStart": "13:43",      // Start time as provided
    "timeOfDayEnd": "23:45",        // End time as provided
    "createdAt": "2024-01-10T12:14:54.540Z",
    "updatedAt": "2024-01-10T12:14:54.540Z"
}`} />

    <Typography variant="subtitle1" sx={{ mt: 3 }}>
        Update Number Plate
    </Typography>
    <CodeBlock code={`PUT /api/number-plates/{id}?tenantId=your_tenant_id
// Request body: Same as POST
// Response: Same as POST response`} />

    <Typography variant="subtitle1" sx={{ mt: 3 }}>
        Delete Number Plate
    </Typography>
    <CodeBlock code={`DELETE /api/number-plates/{id}?tenantId=your_tenant_id`} />

</AccordionDetails>
</Accordion>
)