import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const qrCodeKeys = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="qrcodekeys-content"
            id="qrcodekeys-header"
        >
            <Typography variant="h6">QR Code Keys</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                List QR Code Keys
            </Typography>
            <CodeBlock code={`GET /api/qr-code-keys?tenantId=your_tenant_id&page=0&pageSize=25&search=optional_search_term

// Response
{
    "count": 1,                      // Total number of QR code keys matching the criteria
    "rows": [
        {
            "id": 1,                                 // Unique identifier for the QR code key
            "name": "John",                          // Welcome name shown on kiosk
            "tenantId": 2,                          // Tenant ID
            "responderAliasId": 1,                   // Optional link to responder group
            "internalName": "John Smith",            // Internal reference
            "mobileNumber": "+447700900123",         // Mobile number for sending QR code
            "keySent": "2024-02-27T09:06:07.384Z",   // When the key was last sent
            "startDate": null,                       // date restrictions
            "endDate": null,                         // date restrictions
            "timeOfDayStart": null,                  // time restrictions
            "timeOfDayEnd": null,                    // time restrictions
            "active": true,                          // Whether the key is active
            "autoDeleteOnExpiry": false,             // Whether to auto-delete after expiry
            "securityLevel": null,                   // Security level requirement
            "createdAt": "2024-02-27T09:06:07.384Z", // Date of creation in UTC
            "updatedAt": "2024-02-27T09:06:07.384Z", // Date of last update in UTC
            "ResponderAlias": {                      // Included if responderAliasId is set
                "alias": "Flat 7",
                "id": 1
            }
        }
    ]
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Check if QR Code Keys are shared:
            </Typography>
            <CodeBlock code={`GET /api/qr-code-keys/is-shared?tenantId=your_tenant_id`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Create QR Code Key
            </Typography>
            <CodeBlock code={`POST /api/qr-code-keys?tenantId=your_tenant_id
{
    "name": "John",                  // Required: Welcome name shown on kiosk
    "internalName": "",              // Optional: Internal reference
    "mobileNumber": "+447700900123", // Required: Mobile number to send QR code to
    "sendImmediately": true,         // Optional: Send QR code immediately after creation

    // Alias settings
    "responderAliasId": null,        // Optional: ID of the responder alias to notify

    // Optional to allow the QR code to be used only between these dates & times
    "startDate": "2024-12-29",       // YYYY-MM-DD
    "endDate": "2024-12-30",         // YYYY-MM-DD
    "timeOfDayStart": "13:43",       // HH:mm, required if timeOfDayEnd set
    "timeOfDayEnd": "23:45"          // HH:mm, required if timeOfDayStart set
}

// Success Response
{
    "id": 242,                       // Unique identifier
    "name": "John",                  // Welcome name as provided
    "tenantId": 2,                   // Tenant ID from request
    "internalName": "",              // Internal name as provided
    "mobileNumber": "+447700900123", // Mobile number as provided
    "keySent": "2024-01-10T12:14:54.540Z", // Set if sendImmediately was true
    "responderAliasId": null,        // Responder Alias ID as provided
    "startDate": "2024-12-29",       // Start date as provided
    "endDate": "2024-12-30",         // End date as provided
    "timeOfDayStart": "13:43",       // Start time as provided
    "timeOfDayEnd": "23:45",         // End time as provided
    "createdAt": "2024-01-10T12:14:54.540Z",
    "updatedAt": "2024-01-10T12:14:54.540Z"
}

// Response when SMS sending fails (HTTP 201)
{
    // Same fields as success response, plus:
    "warning": "QR Code created but SMS sending failed"
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Send QR Code Key
            </Typography>
            <CodeBlock code={`POST /api/qr-code-keys/{id}/send?tenantId=your_tenant_id

// Response
{
    "success": true  // Indicates if the key was sent successfully
}`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: Sending a new QR code key will invalidate any previously sent keys for this entry.
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Export QR Code Keys
            </Typography>
            <CodeBlock code={`GET /api/qr-code-keys/export?tenantId=your_tenant_id

// Response: CSV file download containing all QR code keys`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Check QR Code Keys Status
            </Typography>
            <CodeBlock code={`GET /api/tenants-qrcodekeys-status?tenantId=your_tenant_id

// Response
{
    "SettingEnableQRCodeKeys": true  // Whether QR code keys are enabled for this tenant
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Delete QR Code Key
            </Typography>
            <CodeBlock code={`DELETE /api/qr-code-keys/{id}?tenantId=your_tenant_id

// Response
{
    "success": true  // Indicates if the key was deleted successfully
}`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: Deleting a QR code key will permanently remove it and invalidate any previously sent keys.
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Update QR Code Key
            </Typography>
            <CodeBlock code={`PUT /api/qr-code-keys/{id}?tenantId=your_tenant_id
// Request body: Same as POST
// Response: Same as POST response`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Get QR Code Access Logs
            </Typography>
            <CodeBlock code={`GET /api/qr-code-keys/{id}/logs?tenantId=your_tenant_id&page=0&pageSize=10&sortColumn=createdAt&sortDirection=desc&search=optional_search_term

// Response
{
    "count": 25,          // Total number of logs matching criteria
    "rows": [
        {
            "id": 1,
            "eventType": "Access Attempt",
            "success": true,          // Whether the event was successful
            "keyData": "{...}",       // Additional event data
            "failureReason": null,    // Reason for failure if success is false
            "createdAt": "2024-03-14T10:30:00.000Z",
            "User": {                 // User who triggered the event (if applicable)
                "first_name": "John",
                "last_name": "Smith"
            }
        }
        // ... more log entries
    ]
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Export QR Code Access Logs
            </Typography>
            <CodeBlock code={`GET /api/qr-code-keys/{id}/logs/export?tenantId=your_tenant_id

// Response: CSV file download containing all logs for this QR code key`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: The logs include all access attempts, scans, and errors related to this QR code key.
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Get Accessible Tenants by Security Level
            </Typography>
            <CodeBlock code={`GET /api/tenants-by-security-level/{level}?tenantId=your_tenant_id

// Where {level} is:
// - A positive number to get tenants with equal or lower security level
// - 0 to get tenants with no security level set

// Response
[
    {
        "id": 1,
        "OrganisationName": "Device 1",
        "securityLevel": null        // No security level set
    },
    {
        "id": 2,
        "OrganisationName": "Device 2",
        "securityLevel": 1           // Has security level
    }
]`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: This endpoint returns all tenants that would be accessible with a given security level. 
                When level 0 is specified, it returns only tenants with no security level set. 
                For any other level, it returns tenants with equal or lower security levels, plus those with no security level set.
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                QR Code Key Tenant Overrides and Security Levels
            </Typography>
            
            <Typography variant="body2" sx={{ mb: 2 }} color="text.secondary">
                When QR codes are shared across all tenants (kiosks), two mechanisms control access:
            </Typography>

            <Box sx={{ pl: 2, mb: 2 }}>
                <Typography variant="body2" color="text.secondary" sx={{ display: 'list-item' }}>
                    Security Levels: A numeric value that must be equal to or higher than the kiosk's security level
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ display: 'list-item' }}>
                    Tenant Overrides: Explicitly allow access to specific kiosks, bypassing security level checks
                </Typography>
            </Box>

            <Typography variant="body2" sx={{ mb: 2 }} color="text.secondary">
                Both features are only available when QR code sharing is enabled across tenants.
            </Typography>

            <CodeBlock code={`// Get overrides for a specific QR code key
GET /api/qr-code-keys/{id}/overrides?tenantId=your_tenant_id

// Response
[
    {
        "id": 49,                                  // Unique identifier for the override
        "qrCodeKeyId": 240,                        // ID of the QR code key
        "tenantId": 2,                             // ID of the tenant
        "createdAt": "2025-01-10T17:16:34.262Z",   // Date of creation
        "updatedAt": "2025-01-10T17:16:34.262Z",   // Date of last update
        "Tenant": {                                // Tenant details
            "OrganisationName": "Device 1"
        }
    }
]

// To update overrides and security level:
PUT /api/qr-code-keys/{id}?tenantId=your_tenant_id
{
    // ... other QR code key fields ...
    "overrideTenantIds": [1, 2, 3],  // Array of tenant IDs to explicitly allow access
}

// To create with overrides:
POST /api/qr-code-keys?tenantId=your_tenant_id
{
    // ... other QR code key fields ...
    "overrideTenantIds": [1, 2, 3],  // Array of tenant IDs to explicitly allow access
}`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Example: A QR code with security level 2 will only work on kiosks with security level 1 or 2, 
                unless the kiosk's tenant is in the overrideTenantIds list, in which case the security level check is bypassed.
            </Typography>

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: Tenant overrides allow a single QR code key to work across multiple kiosks/tenants. 
                The key will work at any kiosk belonging to the primary tenant or any of the override tenants.
            </Typography>

        </AccordionDetails>
    </Accordion>
);