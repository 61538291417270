import React, { useEffect, useState, useContext, useRef } from 'react';
import { Typography, Button, Box, Grid, Divider, CircularProgress, IconButton, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Layout from '../../components/Layout';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';


import '../../styles.css'; 

export default function Upgrade() {
    const { globalTenantId } = useContext(AuthContext);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const globalTenantIdRef = useRef(globalTenantId);
    const [subscriptionInfoLoading, setSubscriptionInfoLoading] = useState(true);
    const [plans, setPlans] = useState(null);
    const [plansLoading, setPlansLoading] = useState(true);
    const [isAnnualSubscription, setIsAnnualSubscription] = useState(false);
    const [prices, setPrices] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [upgrading, setUpgrading] = useState(false);
    const navigate = useNavigate();
    const  Paddle  =  window.Paddle;
    const { user } = useContext(AuthContext);
    const [ip, setIP] = useState("");

    useEffect(() => { // get local pricing
        if (!plans) return;
        plans.forEach(async (plan) => {
            if (!plan.paddleDetails) return;
            const price = await getPrice(plan.paddleDetails.id);
            setPrices(prevPrices => ({ ...prevPrices, [plan.paddleDetails.id]: price }));
        });
    }, [plans]);
    
    const getIPAddress = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
      };
    
      useEffect(() => {
        getIPAddress();
      }, []);

    useEffect(() => {
        setPlansLoading(true);

        (async () => {
            if (Paddle) {
                const paddleEnv = (await axios.get('/api/setting?name=PADDLE_ENVIRONMENT')).data.value;
                const paddleAuth = (await axios.get('/api/setting?name=PADDLE_TOKEN')).data.value;
                                
                if (paddleEnv == 'sandbox') Paddle.Environment.set(paddleEnv);
                Paddle.Setup({
                    token: paddleAuth,
                    eventCallback: function(data) {
                        if (data.name == 'checkout.completed') {
                            checkoutComplete(data);
                        }
                    }
                });
    
            }
        })();

    }, []);

    useEffect(() => {
        globalTenantIdRef.current = globalTenantId;
    }, [globalTenantId]);
    

    const checkoutComplete = (data) => {

        setTimeout(() => {
            // get priceid from data.items[0].price_id
            let priceId = data.data.items[0].price_id;
            let transactionId = data.data.transaction_id;

            // call api/updateSubscription with priceId and transactionid
            updateSubscription(priceId, transactionId, true);
        }, 1000);

    }
    const fetchPlans = (isAnnual) => {
        // fetch plans
        axios.get('/api/plans', { params: { IsAnnual: isAnnual, tenantId: globalTenantId } } )
        .then(result => {
            setPlans(result.data);
            setPlansLoading(false);

        }).catch(error => {
            console.error('Error fetching plans:', error);
        });
    }

    const updateSubscription = (priceId, transactionId) => {
        setUpgrading(true);
        axios.post('/api/updateSubscription', { paddleId: priceId, transactionId: transactionId })
            .then(result => {
                setUpgrading(false);

                // refresh sub info
                fetchSubscriptionData();
                navigate('/client-admin/', { state: { message: 'Subscription upgraded successfully!' } });


            }).catch(error => {
                console.error('Error updating subscription:', error);
            
                // Default error message
                let errorMessage = 'There was an error updating the subscription. Please try again.';
            
                // Check if the error response is structured
                if (error.response && error.response.data) {
                    if (typeof error.response.data === 'string') {
                        // If the response is a string, use it as the message
                        errorMessage = error.response.data;
                    } else if (error.response.data.message) {
                        // If the response is an object with a 'message' field, use it
                        errorMessage = error.response.data.message + '\n\n' + error.response.data.details;
                    }
                }
            
                // Display the error message
                // Consider using a more integrated UI element than alert for better user experience
                setErrorMessage(errorMessage);
                setShowAlert(true);
            
                // Set loading state to false, or perform other UI updates as needed
                setSubscriptionInfoLoading(false);
            });
            
    }

    const hideUpgradeError = () => {
        setUpgrading(false);
        setShowAlert(false);
        setErrorMessage('');
    }

    const myAccount = () => {
       navigate('/client-admin/my-account');
    }

    const openPaddleCheckout = async (paddleId) => {
        if (paddleId == null) return;


        // upgrading an existing subscription or creating a new one?
        // if subscription exists, then we are upgrading
        if (subscriptionPlan.paddleData.items)
        {
            if (!window.confirm('Are you sure you want to change your subscription? Doing so will automatically and immediately charge or credit depending on where you are in your billing cycle. If you are downgrading, any logs or movement snapshots will be automatically deleted based on the plan limitations.')) return;

            // existing subscription - upgrade
            updateSubscription(paddleId, null);

        }
        else
        {

            var itemsList = [
                {
                priceId: paddleId
                },
            ];
        
            Paddle.Checkout.open({
                settings: {
                    displayMode: "inline",
                    theme: "light",
                    locale: "en",
                    frameTarget: "checkout-container",
                    frameInitialHeight: "450",
                    frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;",
                    allowLogout: false,
                    showAddDiscounts: false,
                    showAddTaxId: false
                },
                items: itemsList,
                 customer:
                 {
                     email: user.email,
                 }
            });

            // Show the checkout overlay
            var overlay = document.querySelector('.checkout-overlay');
            overlay.style.display = 'flex';
        }
    };

    const handleCloseCheckout = () => {
        var overlay = document.querySelector('.checkout-overlay');
        overlay.style.display = 'none';
    }     

    const fetchPageData = () => {
        if (globalTenantId == null) return;
       
        fetchSubscriptionData();

    };

    const fetchSubscriptionData = () => {
        setSubscriptionInfoLoading(true);
        axios.get('/api/customer-subscription')
        .then(result => {
            const newPlan = JSON.parse(JSON.stringify(result.data));  // this must be done to force a re-render
            setSubscriptionPlan(newPlan);

            // Determine if the subscription is annual
            const isAnnual = result.data.paddleData.items && result.data.paddleData.items[0].price.billing_cycle.interval === 'year';
            setIsAnnualSubscription(isAnnual);
            fetchPlans(isAnnual);

            setSubscriptionInfoLoading(false);

        }).catch(error => {
            console.error('Error fetching customer sub:', error);
        });
    }

    useEffect(() => {
        fetchPageData();
    }, [globalTenantId]); // An empty dependency array means this effect will run once after the initial render
    
    

    const getPrice = async (priceId) => {

        // either this is a new customer, or an existing customer upgrading
        // new customers will be based on IP address for pricing
        // existing customers will be based on their subscription/customer record

        let request = {
            items: [{
                priceId: priceId,
                quantity: 1
            }]
        };
    
        // Check if existing customer
        if (subscriptionPlan && subscriptionPlan.paddleData && subscriptionPlan.paddleData.items) {
            request.customerId = subscriptionPlan.paddleData.customer_id;
            request.addressId = subscriptionPlan.paddleData.address_id;
        } else {
            request.customerIpAddress = ip;
        }
        
        let result = await Paddle.PricePreview(request)
        let price = result.data.details.lineItems[0].formattedTotals.total;
        return price;
    }


    return (
        <Layout title='Upgrade Entrinsic Connect'>
            <Grid item xs={12}>
                { subscriptionInfoLoading ? (
                    <Grid container direction="column" spacing={2}>
                        <Grid container item alignItems="center">
                            <Grid item xs={10}>
                                <CircularProgress size={25} />
                            </Grid>
                        </Grid>
                    </Grid>) :
                (
                    <>
                    
                    <Grid item xs={12}>

                            {
                                subscriptionPlan.paddleData.items ?
                                (
                                    <Typography style={{marginBottom: 10}}>
                                        We will automatically refund if downgrading, or charge the difference if upgrading based on the 
                                        time you have already used on your current plan. You can switch plan at any time without penalty and we will always refund on a pro rata basis.
                                    </Typography>
                                )
                                :
                                (
                                    <Typography style={{marginBottom: 10}}>
                                        Choose whether to pay monthly or annually. Annual pricing carries a significant overall discount. 
                                        You can switch plan at any time without penalty and we will always refund on a pro rata basis.
                                    </Typography>
                                )
                            }
                            {
                                plansLoading ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    <>
                                    <Typography variant="body1" component="div" sx={{ backgroundColor: '#888', color: '#fff', padding: '3px', marginBottom: '10px', fontWeight: 'bold', textAlign: 'center', borderRadius: 2 }}>
                                        SELECT A PLAN
                                        <ToggleButtonGroup
                                            value={isAnnualSubscription ? 'annual' : 'monthly'}
                                            exclusive
                                            onChange={(event, newAlignment) => {
                                                const isAnnual = newAlignment === 'annual';
                                                setIsAnnualSubscription(isAnnual);
                                                fetchPlans(isAnnual);
                                            }}
                                            aria-label="Subscription type"
                                            style={{marginLeft: 10}}
                                            sx={{
                                                backgroundColor: 'white', // Set background color to white
                                                '& .MuiToggleButtonGroup-grouped': {
                                                    padding: '2px 6px', // Adjust padding as required
                                                    margin: '5px', // Adjust margin if needed
                                                    border: 0, // Optional: Removes border if desired
                                                },
                                                }}
                                            color='primary'
                                            
                                            >
                                            <ToggleButton value="monthly" aria-label="Monthly">
                                                Monthly
                                            </ToggleButton>
                                            <ToggleButton value="annual" aria-label="Annual">
                                                Annual
                                            </ToggleButton>
                                            
                                        </ToggleButtonGroup>


                                    </Typography>
                                    <Grid container direction="column">
                                        {plans.map(plan => (
                                            <Grid container item alignItems="center" spacing={5}>
                                                <Grid item xs={3}>
                                                    <Typography variant="body1" noWrap><strong>{plan.name}</strong></Typography>
                                                    { plan.paddleDetails && (
                                                    <Typography variant="body2" noWrap style={{color: '#1976d2'}}>
                                                        
                                                    <strong>{prices[plan.paddleDetails.id]}</strong> per {plan.paddleDetails.billing_cycle.interval}
                                                    
                                                    <br/>
                                                    Renews on {(() => {
                                                        const currentDate = new Date();
                                                        const interval = plan.paddleDetails.billing_cycle.interval;

                                                        let nextBilledDate = new Date(currentDate);

                                                        if (interval === 'month') {
                                                            nextBilledDate.setMonth(currentDate.getMonth() + 1);
                                                        } else if (interval === 'year') {
                                                            nextBilledDate.setFullYear(currentDate.getFullYear() + 1);
                                                        }

                                                        return nextBilledDate.toLocaleDateString();
                                                    })()}

                                                    </Typography>)}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body2">{plan.Details}</Typography>
                                                </Grid>    
                                                <Grid item xs={3}>
                                                    {
                                                        subscriptionPlan.plan.PaddleID == plan.PaddleID ? 'Current Plan' : 
                                                        (
                                                            <Button variant="contained" color="primary" onClick={() => openPaddleCheckout(plan.PaddleID)} style={{marginTop:20}}>Choose Plan</Button>
                                                        )
                                                    }
                                                </Grid>
                                                <Grid item xs={12}><Divider style={{marginBottom: 10}}/></Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    </>
                                )
                            }
                            {
                            subscriptionPlan.paddleData.items &&
                            (
                                <Box textAlign='center'>
                                    <Button variant="outlined" color="primary" onClick={() => myAccount()} style={{marginTop:20}}>My Account</Button>
                                </Box>
                            )
                            }
                            
                        </Grid>
                        <Dialog open={upgrading}>
                            <DialogTitle>Changing subscription...</DialogTitle>
                            <DialogContent>
                                {!showAlert && <CircularProgress size={25} />}
                                {showAlert && (
                                    <>
                                        {errorMessage.split("\n").map((line, index) => (
                                            <React.Fragment key={index}>
                                                <Typography variant="body1" sx={{marginBottom: 1}}>{line}</Typography>
                                            </React.Fragment>
                                        ))}
                                        <Button variant="contained" color="primary" onClick={() => hideUpgradeError()} style={{marginTop:20}}>OK</Button>
                                    </>
                                )}
                            </DialogContent>
                        </Dialog>

                        <div class="checkout-overlay">
                        <IconButton className="close-button" onClick={handleCloseCheckout}>
                            <CloseIcon />
                        </IconButton>
                            <div class="checkout-container"></div>
                        </div>

                        
                    </>
                )}
            </Grid>
        </Layout>
    );
}
