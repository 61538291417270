import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const pins = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="pins-content"
            id="pins-header"
        >
            <Typography variant="h6">PINs</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                List PINs
            </Typography>
            <CodeBlock code={`GET /api/pins?tenantId=your_tenant_id

// Response
[
    {
        "id": 1,                                 // Unique identifier for the PIN
        "pin": "1234",                          // The PIN code
        "name": "John",                         // Welcome name shown on kiosk
        "tenantId": 2,                          // Tenant ID
        "responderAliasId": 1,                  // Optional link to responder group
        "internalName": "John Smith",           // Internal reference
        "startDate": null,                      // date restrictions
        "endDate": null,                        // date restrictions
        "timeOfDayStart": null,                 // time restrictions
        "timeOfDayEnd": null,                   // time restrictions
        "createdAt": "2024-02-27T09:06:07.384Z", // Date of creation in UTC
        "updatedAt": "2024-02-27T09:06:07.384Z", // Date of last update in UTC
        "ResponderAlias": {                     // Included if responderAliasId is set
            "alias": "Flat 7",
            "id": 1
        }
    },
    {
        "id": 2,
        "pin": "5678",
        "name": "Sue",
        "tenantId": 2,
        "responderAliasId": null,
        "internalName": "Sue Jones",
        "startDate": "2024-12-29T00:00:00.000Z",      // With date restrictions
        "endDate": "2024-12-29T16:46:28.000Z",
        "timeOfDayStart": "1970-01-01T13:43:00.000Z", // With time restrictions
        "timeOfDayEnd": "1970-01-01T23:45:00.000Z",
        "createdAt": "2024-05-31T13:54:33.179Z",
        "updatedAt": "2024-12-31T16:46:55.354Z",
        "ResponderAlias": null
    }
]`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: The response is an array of all PINs. Each PIN includes its full configuration including any restrictions and notification settings.
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Check if PINs are shared:
            </Typography>
            <CodeBlock code={`GET /api/pins/is-shared?tenantId=your_tenant_id`} />
            
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Create PIN
            </Typography>
            
            <Typography variant="body2" paragraph>
                Create a PIN with optional alias assignment and access restrictions.
            </Typography>
            <CodeBlock code={`POST /api/pins?tenantId=your_tenant_id
{
    "pin": "1234",                // Required: The PIN code, must be a numeric value and unique to this tenant
    "name": "John",               // Required: Welcome name
    "internalName": "",           // Optional: Internal reference

    // Alias settings
    "responderAliasId": null,     // Optional: ID of the responder alias to notify

    // Optional to allow the PIN to be used only between these dates & times
    "startDate": "2024-12-29",    // YYYY-MM-DD
    "endDate": "2024-12-30",      // YYYY-MM-DD
    "timeOfDayStart": "13:43",    // HH:mm, required if timeOfDayEnd set
    "timeOfDayEnd": "23:45"       // HH:mm, required if timeOfDayStart set
}

// Response
{
    "id": 242,                    // Unique identifier
    "pin": "1234",                // PIN code as provided
    "name": "John",               // Welcome name as provided
    "tenantId": 2,                // Tenant ID from request
    "internalName": "",           // Internal name as provided
    "responderAliasId": null,     // Responder Alias ID as provided
    "startDate": "2024-12-29",    // Start date as provided
    "endDate": "2024-12-30",      // End date as provided
    "timeOfDayStart": "13:43",    // Start time as provided
    "timeOfDayEnd": "23:45",      // End time as provided
    "createdAt": "2024-01-10T12:14:54.540Z",
    "updatedAt": "2024-01-10T12:14:54.540Z"
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Update PIN
            </Typography>
            <CodeBlock code={`PUT /api/pins/{id}?tenantId=your_tenant_id
// Request body: Same as POST
// Response: Same as POST response`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Delete PIN
            </Typography>
            <CodeBlock code={`DELETE /api/pins/{id}?tenantId=your_tenant_id`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Check PIN Status
            </Typography>
            <CodeBlock code={`GET /api/tenants-pins-status?tenantId=your_tenant_id

// Response
{
    "SettingIncludePINSButton": true  // Whether PINs are enabled for this tenant
}`} />

        </AccordionDetails>
    </Accordion>
); 