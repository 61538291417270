import React from 'react';
import { 
    Dialog, DialogTitle, DialogContent, DialogActions,
    Grid, Switch, TextField, FormControlLabel, Button, Checkbox,
    Typography
} from '@mui/material';

const GateOpeningTimes = ({ 
    open, 
    onClose, 
    settings, 
    gateSchedule, 
    handleInputChange,
    handleScheduleChange 
}) => {
    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Gate Opening Times</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {settings?.SettingGateOpeningRestrictions && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption" color="textSecondary">
                                    Gate is closed by default. Enable days and set times to allow access.
                                </Typography>
                            </Grid>
                            {Object.entries(gateSchedule).map(([day, schedule]) => (
                                <Grid container item xs={12} key={day} spacing={1} alignItems="center">
                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    size="small"
                                                    checked={schedule.enabled}
                                                    onChange={(e) => handleScheduleChange(day, 'enabled', e.target.checked)}
                                                />
                                            }
                                            label={day.charAt(0).toUpperCase() + day.slice(1)}
                                        />
                                    </Grid>
                                    {schedule.enabled ? (
                                        <>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size="small"
                                                    type="time"
                                                    value={schedule.start}
                                                    onChange={(e) => handleScheduleChange(day, 'start', e.target.value)}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size="small"
                                                    type="time"
                                                    value={schedule.end}
                                                    onChange={(e) => handleScheduleChange(day, 'end', e.target.value)}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={8}>
                                            <Typography variant="caption" color="error">
                                                Closed
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default GateOpeningTimes; 