import LoginPage from './containers/LoginPage';
import HomePage from './containers/HomePage';
import NotFoundPage from './containers/NotFoundPage';
import SignUpPage from './containers/SignUp';
import VerifiedPage from './containers/Verified';
import ResetPassword from './containers/ResetPassword';
import HandleResetPassword from './containers/HandleResetPassword';
import ClientAdminLogsPage from './containers/ClientAdmin/Logs';
import NumberPlatesPage from './containers/ClientAdmin/NumberPlates';
import PinsPage from './containers/ClientAdmin/Pins';
import SettingsPage from './containers/ClientAdmin/Settings';
import VideoMessagesPage from './containers/ClientAdmin/VideoMessages';
import UsersPage from './containers/ClientAdmin/Users';
import UnauthorizedPage from './containers/Unauthorized';
import CustomersPage from './containers/Admin/Customers';
import Snapshots from './containers/ClientAdmin/Snapshots';
import ResponderAliases from './containers/ClientAdmin/ResponderAliases';
import Upgrade from './containers/ClientAdmin/Upgrade';
import MyAccount from './containers/ClientAdmin/MyAccount';
import PayPage from './containers/Pay';
import HelpPage from './containers/Help';
import MovementVideos from './containers/ClientAdmin/MovementVideos';
import QrCodeKeys from './containers/ClientAdmin/QrCodeKeys';
import Developers from './containers/ClientAdmin/Developers';

export const routes = [
    { path: '/login', component: LoginPage, isPublic: true },
    { path: '/notfound', component: NotFoundPage, isPublic: true },
    { path: '/unauthorized', component: UnauthorizedPage, isPublic: true },
    { path: '/reset-password', component: ResetPassword, isPublic: true },
    { path: '/handle-reset-password', component: HandleResetPassword, isPublic: true },
    { path: '/signup', component: SignUpPage, isPublic: true },
    { path: '/verified', component: VerifiedPage, isPublic: true },
    { path: '/pay', component: PayPage, isPublic: true },
    { path: '/help', component: HelpPage, isPublic: true },
    { path: '/client-admin/', component: HomePage, isPublic: false, requiredRole: ['CLIENT_ADMIN', 'CLIENT_USER']  },
    { path: '/client-admin/logs', component: ClientAdminLogsPage, isPublic: false, requiredRole: ['CLIENT_ADMIN', 'CLIENT_USER']  },
    { path: '/client-admin/number-plates', component: NumberPlatesPage, isPublic: false, requiredRole: ['CLIENT_ADMIN', 'CLIENT_USER']  },
    { path: '/client-admin/pins', component: PinsPage, isPublic: false, requiredRole: ['CLIENT_ADMIN', 'CLIENT_USER']  },
    { path: '/client-admin/settings', component: SettingsPage, isPublic: false, requiredRole: ['CLIENT_ADMIN', 'CLIENT_USER']  },
    { path: '/client-admin/video-messages', component: VideoMessagesPage, isPublic: false, requiredRole: ['CLIENT_ADMIN', 'CLIENT_USER']  },
    { path: '/client-admin/snapshots', component: Snapshots, isPublic: false, requiredRole: ['CLIENT_ADMIN', 'CLIENT_USER']  },
    { path: '/client-admin/movement-videos', component: MovementVideos, isPublic: false, requiredRole: ['CLIENT_ADMIN', 'CLIENT_USER']  },
    { path: '/client-admin/qr-code-keys', component: QrCodeKeys, isPublic: false, requiredRole: ['CLIENT_ADMIN', 'CLIENT_USER'] },
    { path: '/client-admin/users', component: UsersPage, isPublic: false, requiredRole: ['CLIENT_ADMIN'] },
    { path: '/client-admin/responder-aliases', component: ResponderAliases, isPublic: false, requiredRole: ['CLIENT_ADMIN'] },
    { path: '/client-admin/upgrade', component: Upgrade, isPublic: false, requiredRole: ['CLIENT_ADMIN'] },
    { path: '/client-admin/my-account', component: MyAccount, isPublic: false, requiredRole: ['CLIENT_ADMIN'] },
    { path: '/admin/customers', component: CustomersPage, isPublic: false, requiredRole: ['SUPER_ADMIN'] },
    { path: '/client-admin/developers', component: Developers, isPublic: false, requiredRole: ['CLIENT_ADMIN'] },
    { path: '*', component: NotFoundPage, isPublic: true }

  ];
  