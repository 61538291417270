import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Layout from '../../components/Layout';
import { TextField, Button, Checkbox, FormControlLabel, Container, Grid, Box, Dialog, useTheme, useMediaQuery, Card, CardContent, Typography, Stack, Collapse } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SnapshotGrid = () => {

    // state hooks
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
      });
      const [sortModel, setSortModel] = useState([{
        field: 'id',
        sort: 'desc',
      }]);
      
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false); // New state for loading status
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [open, setOpen] = useState(false); // Add this state to control dialog
    const [snapshotURL, setSnapshotURL] = useState(null); // Add this state to store the snapshot blob URL
    const [deletingIds, setDeletingIds] = useState(new Set());
    const { globalTenantId } = useContext(AuthContext);

    const [filters, setFilters] = useState({
        search: '',
        movementSnapshotsOnly: false,
        anprSnapshotsOnly: false,
    });
    const [openImage, setOpenImage] = useState(false);
    const [largeImageSrc, setLargeImageSrc] = useState(null);
    const filterRef = useRef(null);

    // date range filter
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    // effect hooks
    useEffect(() => {
        if (!globalTenantId) return;
        fetchData();
    }, [paginationModel.page, sortModel[0]?.field, sortModel[0]?.sort, filters, globalTenantId]);

    useEffect(() => {
        if (!globalTenantId) return;
        setPaginationModel(prev => ({
            ...prev,
            page: 0
        }));
        setRows([]);
    }, [globalTenantId]);

    useEffect(() => {
        if (filterRef.current) {
            const formHeight = filterRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            const calculatedHeight = viewportHeight - formHeight - 150;
            
            setGridHeight(calculatedHeight);
        }
    }, []);  // Dependencies can be adjusted as needed
    const fetchData = () => {
        setLoading(true);

        const requestBody = {
            ...filters,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sortColumn: sortModel[0].field,
            sortDirection: sortModel[0].sort,
            movementOnly: filters.movementSnapshotsOnly,
            anprOnly: filters.anprSnapshotsOnly
        };

        axios.get(`/api/kiosk-snapshots?tenantId=${globalTenantId}`, { params: requestBody })
        .then(result => {
            const data = result.data;
            if (isMobile && paginationModel.page > 0) {
                const existingIds = new Set(rows.map(row => row.id));
                const newRows = data.rows.filter(row => !existingIds.has(row.id));
                setRows(prevRows => [...prevRows, ...newRows]);
            } else {
                setRows(data.rows || []);
            }
            setRowCount(data.count || 0);
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => {
            setLoading(false);
        });
    };

    const handleDelete = (id) => {
        if (!window.confirm("Are you sure you want to delete this item?")) return;
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/kiosk-snapshots/${id}`, { params: { tenantId: globalTenantId } })
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Refresh the list of pins
            fetchData();
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            console.error('Error deleting snapshot:', error)
        });
    }
    const columns = [
        { field: "id", headerName: "ID", width: 90 },
        { field: "createdAt", headerName: "Date/Time", width: 250,
            valueGetter: (params) => {
                const date = new Date(params.value);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                return `${day}/${month}/${year} ${hours}:${minutes}`;
            }
        },
        {
            field: "CloudinaryUrl",
            headerName: "Preview",
            width: 150,
            renderCell: (params) => {
                if (params.value) {
                    return <img src={params.value} alt="snapshot preview" width="50" onClick={() => handleImageClick(params.value)}/>;
                } else {
                    return <div>No preview</div>;
                }
            }
        },
        { field: 'delete', headerName: '', width: 100, 
        renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id)}
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
        ),
        },
    ];

    const handleImageClick = (src) => {
        setLargeImageSrc(src);
        setOpenImage(true);
    };
    
    const handleCloseImage = () => {
        setOpenImage(false);
    };

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        // Update filters with date range
        setFilters(prev => ({
            ...prev,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
        }));
        setPaginationModel(prev => ({
            ...prev,
            page: 0
        }));
        setRows([]);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const FilterToggleButton = () => (
        <Box sx={{ 
            display: { xs: 'flex', md: 'none' }, 
            mb: 1,
            px: 1
        }}>
            <Button
                onClick={() => setIsFilterVisible(!isFilterVisible)}
                startIcon={isFilterVisible ? <KeyboardArrowUpIcon /> : <FilterListIcon />}
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                    py: 0.5
                }}
            >
                {isFilterVisible ? 'Hide Filters' : 'Show Filters'}
            </Button>
        </Box>
    );

    const handleLoadMore = () => {
        setPaginationModel(prev => ({
            ...prev,
            page: prev.page + 1
        }));
    };

    return (
        <Layout title='Snapshots'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FilterToggleButton />
                
                <Collapse in={!isMobile || isFilterVisible}>
                    <form onSubmit={handleFilterSubmit} ref={filterRef}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={6} md={3}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => { setStartDate(newValue); }}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => { setEndDate(newValue); }}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="movementSnapshotsOnly"
                                            checked={filters.movementSnapshotsOnly}
                                            onChange={handleFilterChange}
                                        />
                                    }
                                    label="Only movement"
                                />
                            </Grid> 
                            <Grid item xs={12} sm={6} md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="anprSnapshotsOnly"
                                            checked={filters.anprSnapshotsOnly}
                                            onChange={handleFilterChange}
                                        />
                                    }
                                    label="Only ANPR"
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Collapse>

                {isMobile && isFilterVisible && <Box sx={{ mb: 2 }} />}

                <Box my={4}>
                    {isMobile ? (
                        <>
                            <Stack spacing={2}>
                                {rows.map((row) => (
                                    <Card key={row.id}>
                                        <CardContent>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={5}>
                                                    {row.CloudinaryUrl ? (
                                                        <img 
                                                            src={row.CloudinaryUrl} 
                                                            alt="snapshot preview" 
                                                            style={{ 
                                                                width: '100%', 
                                                                height: 'auto',
                                                                cursor: 'pointer' 
                                                            }}
                                                            onClick={() => handleImageClick(row.CloudinaryUrl)}
                                                        />
                                                    ) : (
                                                        <Typography>No preview</Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                                        {new Date(row.createdAt).toLocaleString()}
                                                    </Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                        ID: {row.id}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            
                                            <Box sx={{ mt: 2 }}>
                                                <Button
                                                    onClick={() => handleDelete(row.id)}
                                                    variant="outlined"
                                                    color="secondary"
                                                    disabled={deletingIds.has(row.id)}
                                                    fullWidth
                                                >
                                                    {deletingIds.has(row.id) ? <CircularProgress size={24} /> : 'Delete'}
                                                </Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Stack>
                            
                            {rows.length > 0 && rows.length < rowCount && (
                                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                                    <Button 
                                        onClick={handleLoadMore}
                                        variant="outlined"
                                        disabled={loading}
                                    >
                                        {loading ? <CircularProgress size={24} /> : 'Load More'}
                                    </Button>
                                </Box>
                            )}
                        </>
                    ) : (
                        <div style={{ height: gridHeight, width: '100%' }}>
                            <DataGrid
                                loading={loading} 
                                rows={rows} 
                                columns={columns} 
                                pageSizeOptions={[10]}
                                paginationMode='server'
                                paginationModel={paginationModel}
                                sortModel={sortModel}
                                onPaginationModelChange={setPaginationModel}
                                onSortModelChange={setSortModel}
                                rowCount={rowCount}
                                sortingOrder={['desc', 'asc']}
                            />
                        </div>
                    )}
                </Box>

                <Dialog 
                    open={openImage} 
                    onClose={handleCloseImage} 
                    fullWidth
                    maxWidth="md"
                >
                    <img 
                        src={largeImageSrc} 
                        alt="Large snapshot" 
                        style={{ 
                            width: '100%', 
                            height: 'auto',
                            maxHeight: '90vh',
                            objectFit: 'contain' 
                        }} 
                    />
                </Dialog>
            </LocalizationProvider>
        </Layout>
    );
}

export default SnapshotGrid;
