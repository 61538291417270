import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({ code, language = 'javascript' }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(code);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Box 
                sx={{ 
                    backgroundColor: '#292d3e', // Dark background
                    p: 2, 
                    borderRadius: 1,
                    '&:hover .copy-button': {
                        opacity: 1
                    }
                }}
            >
                <Tooltip title="Copy to clipboard">
                    <IconButton 
                        onClick={handleCopy}
                        size="small"
                        sx={{ 
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            opacity: 0,
                            transition: 'opacity 0.2s',
                            color: 'white'
                        }}
                        className="copy-button"
                    >
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <SyntaxHighlighter 
                    language={language}
                    style={atomDark}
                    customStyle={{
                        margin: 0,
                        borderRadius: 4,
                        fontSize: '0.9rem',
                    }}
                >
                    {code}
                </SyntaxHighlighter>
            </Box>
        </Box>
    );
};

export default CodeBlock; 