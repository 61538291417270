export const DEVELOPER_TERMS = {
    title: "API Developer Terms and Conditions",
    lastUpdated: "2024-01-10",
    terms: [
        {
            heading: "1. API Usage and Access",
            clauses: [
                "1.1. You agree to use the API only for its intended purpose and in accordance with all applicable laws and regulations.",
                "1.2. You will not attempt to circumvent any rate limits or security measures implemented in the API.",
                "1.3. You are responsible for maintaining the security of your API credentials and must not share them with third parties.",
                "1.4. We reserve the right to monitor API usage and suspend or terminate access if abuse is detected."
            ]
        },
        {
            heading: "2. Data Protection and Privacy",
            clauses: [
                "2.1. You must comply with all applicable data protection laws, including but not limited to GDPR and local privacy regulations.",
                "2.2. You will implement appropriate security measures to protect any personal data processed through the API.",
                "2.3. You must obtain necessary consents for any data collection and processing activities.",
                "2.4. You will promptly report any data breaches that may affect our users or systems."
            ]
        },
        {
            heading: "3. Technical Requirements",
            clauses: [
                "3.1. You must implement proper error handling in your applications.",
                "3.2. You will follow our API documentation and best practices for integration.",
                "3.3. You agree to maintain compatibility with API updates within reasonable timeframes.",
                "3.4. You will not implement automated systems that could overload our services."
            ]
        },
        {
            heading: "4. Intellectual Property",
            clauses: [
                "4.1. All intellectual property rights in the API remain our exclusive property.",
                "4.2. You may not reverse engineer or attempt to extract our source code.",
                "4.3. You must respect all copyright and trademark rights in your API usage.",
                "4.4. Any derivative works must be clearly attributed to your organization."
            ]
        },
        {
            heading: "5. Liability and Indemnification",
            clauses: [
                "5.1. You agree to indemnify us against any claims arising from your use of the API.",
                "5.2. We provide the API 'as is' without any warranties of any kind.",
                "5.3. You are responsible for any damages caused by your misuse of the API.",
                "5.4. We reserve the right to modify or discontinue the API service with reasonable notice."
            ]
        },
        {
            heading: "6. Security Requirements",
            clauses: [
                "6.1. You must implement industry-standard security practices in your applications.",
                "6.2. Regular security audits of your API usage are required.",
                "6.3. You must promptly apply any security-related updates we provide.",
                "6.4. You will not use the API to transmit malware or harmful code."
            ]
        }
    ],
    footer: "By accepting these terms, you acknowledge that you have read, understood, and agree to be bound by all the conditions stated above."
}; 