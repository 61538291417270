import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const logs = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="logs-content"
            id="logs-header"
        >
            <Typography variant="h6">Logs</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                List Logs
            </Typography>
            <CodeBlock code={`POST /api/logs?tenantId=your_tenant_id
{
    "search": "",                    // Optional: Search term to filter logs
    "excludeKeepalive": false,       // Optional: Exclude keepalive events
    "startDate": "2024-03-01",       // Optional: ISO date string
    "endDate": "2024-03-14",         // Optional: ISO date string
    "page": 0,                       // Required: Page number (0-based)
    "pageSize": 10,                  // Required: Number of items per page
    "sortColumn": "date",            // Required: Column to sort by
    "sortDirection": "desc"          // Required: Sort direction ("asc" or "desc")
}

// Response
{
    "count": 150,         // Total number of logs matching criteria
    "rows": [
        {
            "id": 1,
            "date": "2024-03-14T10:30:00.000Z",  // Timestamp of the event
            "event": "Door opened",               // Event description or image data
            "IsImageData": false,                 // Whether event contains an image
            "User": {                            // User who triggered the event (if applicable)
                "first_name": "John",
                "last_name": "Smith"
            }
        },
        {
            "id": 2,
            "date": "2024-03-14T10:29:00.000Z",
            "event": "data:image/jpeg;base64,...", // Base64 encoded image
            "IsImageData": true,
            "User": null
        }
        // ... more log entries
    ]
}`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: The logs endpoint supports server-side pagination, sorting, and filtering. The event field may contain either text or base64-encoded image data, indicated by the IsImageData flag.
            </Typography>
        </AccordionDetails>
    </Accordion>
); 