import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const snapshots = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="snapshots-content"
            id="snapshots-header"
        >
            <Typography variant="h6">Snapshots</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                List Snapshots
            </Typography>
            <CodeBlock code={`GET /api/kiosk-snapshots?tenantId=your_tenant_id
{
    "startDate": "2024-03-01",       // Optional: ISO date string
    "endDate": "2024-03-14",         // Optional: ISO date string
    "page": 0,                       // Required: Page number (0-based)
    "pageSize": 10,                  // Required: Number of items per page
    "sortColumn": "id",              // Required: Column to sort by
    "sortDirection": "desc",         // Required: Sort direction ("asc" or "desc")
    "movementOnly": false,           // Optional: Filter for movement snapshots only
    "anprOnly": false               // Optional: Filter for ANPR snapshots only
}

// Response
{
    "count": 150,         // Total number of snapshots matching criteria
    "rows": [
        {
            "id": 1,
            "createdAt": "2024-03-14T10:30:00.000Z",  // Timestamp of the snapshot
            "CloudinaryUrl": "https://...",            // URL to the snapshot image
        }
        // ... more snapshot entries
    ]
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Delete Snapshot
            </Typography>
            <CodeBlock code={`DELETE /api/kiosk-snapshots/{id}?tenantId=your_tenant_id

// Response: 200 OK on successful deletion`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: The snapshots endpoint supports server-side pagination, sorting, and filtering. Images are stored and served via Cloudinary URLs.
            </Typography>
        </AccordionDetails>
    </Accordion>
); 