import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';
export const responderAliases = (
<Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="responder-content"
                            id="responder-header"
                        >
                            <Typography variant="h6">Responder Aliases and Users</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="subtitle1" gutterBottom>
                                Purpose of Aliases
                            </Typography>
                            <Box sx={{ pl: 2, mb: 2 }}>
                                <Typography variant="body2" sx={{ display: 'list-item' }}>
                                    They group users who should be notified for automated access events (number plate reads, QR codes, or PINs)
                                </Typography>
                                <Typography variant="body2" sx={{ display: 'list-item' }}>
                                    When enabled with 'ShowOnKiosk', they appear as selectable options on the kiosk interface for visitors and deliveries to choose who they want to speak to
                                </Typography>
                            </Box>

                            <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>
                                Important Notes
                            </Typography>
                            <Box sx={{ pl: 2, mb: 2 }}>
                                <Typography variant="body2" sx={{ display: 'list-item' }}>
                                    Aliases are global across all kiosks
                                </Typography>
                                <Typography variant="body2" sx={{ display: 'list-item' }}>
                                    The 'ShowOnKiosk' flag controls visibility in the kiosk interface
                                </Typography>
                                <Typography variant="body2" sx={{ display: 'list-item' }}>
                                    Users in an alias group receive notifications for all configured trigger events
                                </Typography>
                            </Box>
                            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                List Available Users
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Users can be assigned to aliases or used for 2-step verification on number plates.
                            </Typography>
                            <CodeBlock code={`GET /api/users?tenantId=your_tenant_id

// Response
[
    {
        "id": 1,
        "first_name": "John",
        "last_name": "Smith",
        "email": "john.smith@example.com",
        "role": "CLIENT_USER"
    },
    ...
]`} />
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                Check if Aliases are Enabled
                            </Typography>
                            <CodeBlock code={`GET /api/responder-aliases-isenabled?tenantId=your_tenant_id

// Response
{
    "SettingAllowKioskResponderChoice": true,    // Enable responder selection on kiosk
    "SettingAllowAliasesForNumberPlates": true,  // Enable aliases for ANPR
    "SettingAllowAliasesForPINs": true,         // Enable aliases for PIN entries
    "SettingAllowAliasesForQrCodeKeys": false    // Enable aliases for QR code scans
}`} />

                            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                List Available Aliases
                            </Typography>
                            <CodeBlock code={`GET /api/responder-aliases?tenantId=your_tenant_id

// Response
[
    {
        "id": 3,
        "alias": "Flat 5",              // Display name for the alias
        "customerId": 3,             // Customer ID
        "showOnKiosk": true,         // Indicates visibility in kiosk interface
        "createdAt": "2024-12-28T13:08:40.631Z",
        "updatedAt": "2024-12-28T13:08:40.631Z",
        "ResponderAliasUsers": [     // Array of users associated with this alias
            {
                "id": 5,
                "User": {
                    "id": 5,
                    "first_name": "Jim",
                    "last_name": "Smith"
                }
            },
            {
                "id": 6,
                "User": {
                    "id": 21,
                    "first_name": "Lori",
                    "last_name": "Davis"
                }
            }
        ]
    },
    // ... more aliases
]`} />

                            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                Create Alias
                            </Typography>
                            <CodeBlock code={`POST /api/responder-aliases?tenantId=your_tenant_id
{
    "alias": "Flat 6",         // Required: Name of the alias group
    "showOnKiosk": true,       // Optional: Controls visibility in kiosk interface
    "users": [                 // Optional: Array of users to be associated with the alias
        {
            "id": 5
        },
        {
            "id": 6
        }
    ]
}

// Response
{
    "id": 15,
    "alias": "Flat 6",
    "customerId": 3,
    "showOnKiosk": null,
    "createdAt": "2025-01-11T11:01:35.482Z",
    "updatedAt": "2025-01-11T11:01:35.482Z",
    "ResponderAliasUsers": [     // Array of users associated with this alias
        {
            "id": 30,
            "User": {
                "id": 5,
                "first_name": "Joe",
                "last_name": "Bloggs"
            }
        },
        {
            "id": 31,
            "User": {
                "id": 6,
                "first_name": "Susan",
                "last_name": "Boyle"
            }
        }
    ]
}`} />

                            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                Update Alias
                            </Typography>
                            <Box sx={{ pl: 2, mb: 2 }}>
                                <Typography variant="body2" sx={{ display: 'list-item' }}>
                                    If the users array is not included in the request, all user associations will be removed
                                </Typography>
                            </Box>
                            <CodeBlock code={`PUT /api/responder-aliases/{id}?tenantId=your_tenant_id
{
    "alias": "Flat 6",         // Required: New name for the alias
    "showOnKiosk": true,       // Optional: Update kiosk visibility
    "users": [                 // Optional: Array of users to be associated with the alias
        {
            "id": 5
        },
        {
            "id": 6
        }
    ]
}

// Response
{
    "id": 11,
    "alias": "Flat 6",
    "customerId": 3,
    "showOnKiosk": null,
    "createdAt": "2025-01-11T10:53:21.372Z",
    "updatedAt": "2025-01-11T11:00:20.682Z",
    "ResponderAliasUsers": [     // Array of users associated with this alias
        {
            "id": 28,
            "User": {
                "id": 5,
                "first_name": "Joe",
                "last_name": "Bloggs"
            }
        },
        {
            "id": 29,
            "User": {
                "id": 6,
                "first_name": "Susan",
                "last_name": "Boyle"
            }
        }
    ]
}`} />

                            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                Delete Alias
                            </Typography>
                            <CodeBlock code={`DELETE /api/responder-aliases/{id}?tenantId=your_tenant_id

// Response
{
    "message": "Alias deleted successfully"
}`} />

                           
                        </AccordionDetails>
                    </Accordion>)