import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const tenants = (
    <Accordion defaultExpanded>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="introduction-content"
            id="introduction-header"
        >
            <Typography variant="h6">Introduction</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="body2" paragraph>
                Entrinsic is a powerful access control and visitor management platform that enables developers to manage and configure access control through gates, doors and barriers through mechanisms such as ANPR, QR codes, PINs and live video intercom.
                Through this API, you can programmatically control the data and settings for the available number plates for the ANPR, the QR code access keys, and the PINs available on the kiosks.
                You can also access logs, movement video, recorded video messages and snapshot data.
                Whether you're managing access to a gated property or community, smart apartment complex, parking facility, or office building, you can configure access permissions, tenant settings, number plates, PINs, QR codes and much more.
                The API supports multi-tenant architectures, allowing you to maintain separate configurations and access controls across multiple locations or devices while keeping each security context isolated.
                Additionally, our webhook system enables real-time notifications for various events, allowing you to build responsive integrations that automatically react to access events.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
                API Overview
            </Typography>
            <Typography variant="body2" paragraph>
                This is a RESTful API that uses standard HTTP methods and JSON for data exchange. All requests and responses are in JSON format, and standard HTTP status codes are used to indicate success or failure.
            </Typography>
            
            <Box sx={{ pl: 2, mb: 2 }}>
                <Typography variant="body2" sx={{ display: 'list-item' }}>
                    GET for retrieving data
                </Typography>
                <Typography variant="body2" sx={{ display: 'list-item' }}>
                    POST for creating new records and complex queries
                </Typography>
                <Typography variant="body2" sx={{ display: 'list-item' }}>
                    PUT for updating existing records
                </Typography>
                <Typography variant="body2" sx={{ display: 'list-item' }}>
                    DELETE for removing records
                </Typography>
            </Box>

            <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>
                Authentication
            </Typography>
            <Typography variant="body2" paragraph>
                All API requests require authentication via a JWT token. This token should be included in the Authorization header of each request:
            </Typography>
            <CodeBlock code={`Authorization: Bearer your_jwt_token`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>
                Understanding Tenants
            </Typography>
            <Typography variant="body2" paragraph>
                A tenant represents a specific device or location in the system (e.g., a kiosk, ANPR camera, or assistance button). When you authenticate, you'll receive an array of allowedTenantIds that you have access to.
            </Typography>
            
            <Typography variant="body2" paragraph>
                Most endpoints require a tenantId parameter to specify which device you're working with. This can be provided either as a query parameter or in the request body:
            </Typography>
            <CodeBlock code={`GET /api/endpoint?tenantId=your_tenant_id
// or
POST /api/endpoint
{
    "tenantId": "your_tenant_id",
    // ... other parameters
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>
                Common Response Format
            </Typography>
            <Typography variant="body2" paragraph>
                Paginated endpoints follow a standard response format:
            </Typography>
            <CodeBlock code={`{
    "count": 150,    // Total number of records
    "rows": [        // Array of records for current page
        {
            "id": 1,
            // ... record fields
        }
    ]
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>
                Error Handling
            </Typography>
            <Typography variant="body2" paragraph>
                Errors return appropriate HTTP status codes and a JSON response with error details:
            </Typography>
            <CodeBlock code={`{
    "error": "Error message description"
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>
                Webhooks
            </Typography>
            <Typography variant="body2" paragraph>
                Our webhook system provides real-time notifications for various access events including QR code scans, PIN entries, number plate readings, gate operations, and movement detections. When these events occur, our system sends HTTP POST requests to your specified endpoints with detailed event information.
            </Typography>
            <Typography variant="body2" paragraph>
                We also support interactive webhooks for real-time access verification. These allow you to approve or deny access through your own systems when QR codes are scanned, PINs are entered, or number plates are read. Your endpoint responds with an approval or denial, making it possible to integrate with external security or validation systems.
            </Typography>
        </AccordionDetails>
    </Accordion>
);