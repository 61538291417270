import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const authentication = (developerEmail) => (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="authentication-content"
            id="authentication-header"
        >
            <Typography variant="h6">Authentication</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="body2" gutterBottom>
                First, obtain your JWT token by logging in:
            </Typography>
            <CodeBlock code={`POST /api/login
{
    "email": "${developerEmail}",
    "password": "your_password"
}

// Response
{
    "success": true,
    "message": "Logged in successfully",
    "token": "eyJhbGciOiJIUzI1NiIs...",        // JWT token for API requests
    "refreshToken": "b0d65a2b5aea2d4f...",     // Token for refreshing JWT
    "cloudinaryToken": "exp=1736534040~acl=...", // Token for Cloudinary media access
    "role": "DEVELOPER",                        // User role
    "firstName": "Developer",                   // User's first name
    "lastName": "User",                         // User's last name
    "email": "developer@example.com",           // User's email
    "id": 35,                                  // User's ID
    "tenantId": null,                          // Primary tenant ID (if any)
    "allowedTenantIds": [2, 3, 5, 7],          // List of accessible tenants
    "verified": true,                          // Whether email is verified
}

// Cookies Set Automatically
jwtToken=eyJhbGciOiJIUzI1NiIs...       // HTTP-only cookie
refreshToken=b0d65a2b5aea2d4f...       // HTTP-only cookie`} />

            <Typography variant="body2" sx={{ mt: 2 }}>
                Add the JWT token to all subsequent requests:
            </Typography>
            <CodeBlock code={`Authorization: Bearer YOUR_JWT_TOKEN`} />

            <Typography variant="body2" sx={{ mt: 2 }}>
                To refresh an expired token:
            </Typography>
            <CodeBlock code={`POST /api/refreshtoken
{
    "refreshToken": "your_refresh_token"
}

// Response is the same format as the login response`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: The JWT token expires after 1 hour. The cloudinaryToken is required for accessing media content (snapshots, videos, etc.) and the allowedTenantIds array indicates which tenants you can access.
            </Typography>
        </AccordionDetails>
    </Accordion>
);