import React from 'react';
import { 
    Box, 
    Grid, 
    Card, 
    CardContent, 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ApiIcon from '@mui/icons-material/Api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';
import { tenants } from './tenants';
import { authentication } from './authentication';
import { numberPlates } from './numberPlates';
import { responderAliases } from './responderAliases';
import { pins } from './pins';
import { qrCodeKeys } from './qrCodeKeys';
import { logs } from './logs';
import { snapshots } from './snapshots';
import { movementVideos } from './movementVideos';
import { videoMessages } from './videoMessages';
import { settings } from './settings';
import { webhooks } from './webhooks';
import { kioskMessages } from './kioskMessages';
import { kioskStatus } from './kioskStatus';

const ApiDocumentation = ({ developerEmail }) => {

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <ApiIcon sx={{ mr: 1 }} />
                <Typography variant="h6">
                    API Documentation
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {tenants}
                </Grid>
                <Grid item xs={12}>
                   {authentication(developerEmail)}
                </Grid>
                <Grid item xs={12}>
                   {settings}
                </Grid>
                <Grid item xs={12}>
                   {numberPlates}
                </Grid>
                <Grid item xs={12}>
                    {pins}
                </Grid>
                <Grid item xs={12}>
                    {qrCodeKeys}
                </Grid>
                <Grid item xs={12}>
                    {responderAliases}
                </Grid>
                <Grid item xs={12}>
                    {logs}
                </Grid>
                <Grid item xs={12}>
                    {snapshots}
                </Grid>
                <Grid item xs={12}>
                    {movementVideos}
                </Grid>
                <Grid item xs={12}>
                    {videoMessages}
                </Grid>
                <Grid item xs={12}>
                    {kioskMessages}
                </Grid>
                <Grid item xs={12}>
                    {kioskStatus}
                </Grid>
                <Grid item xs={12}>
                    {webhooks}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ApiDocumentation; 