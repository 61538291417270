import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LogsIcon from '@mui/icons-material/Topic';
import PreviewIcon from '@mui/icons-material/Preview';
import PinIcon from '@mui/icons-material/Pin';
import ContactsIcon from '@mui/icons-material/Contacts';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Badge } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import { styled } from '@mui/material/styles';

const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  position: 'relative',
  marginTop: 0,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 16,
    right: 16,
    background: `linear-gradient(
      to right,
      transparent,
      ${theme.palette.primary.main}15 50%,
      transparent
    )`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 16,
    right: 16,
    height: '1px',
    background: `linear-gradient(
      to right,
      transparent,
      ${theme.palette.primary.main} 50%,
      transparent
    )`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&.Mui-selected::before, &.Mui-selected::after': {
    opacity: 0.5,
  }
}));

export function MainListItems(props) {
  const { userRole, unreadCount, onNavigate, currentPath } = props;

  const handleNavigation = (navigateFunction) => {
    if (onNavigate && !onNavigate()) {
      return;
    }
    navigateFunction();
  };

  return (
    <div style={{ marginTop: -8 }}>
      {userRole === 'SUPER_ADMIN' && (
        <StyledListItemButton 
          onClick={() => handleNavigation(props.onGoToCustomers)}
          selected={currentPath === '/client-admin/customers'}
        >
          <ListItemIcon>
            <PeopleIcon/>
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </StyledListItemButton>
      )}
      {userRole.startsWith('CLIENT_') && (
        <>
          <StyledListItemButton 
            onClick={() => handleNavigation(props.onGoToDashboard)}
            selected={currentPath === '/client-admin/'}
            id='dashboard_button'
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </StyledListItemButton>
          <StyledListItemButton 
            onClick={() => handleNavigation(props.onGoToLogs)}
            selected={currentPath === '/client-admin/logs'}
          >
            <ListItemIcon>
              <LogsIcon />
            </ListItemIcon>
            <ListItemText primary="Logs" />
          </StyledListItemButton>
          <StyledListItemButton 
            onClick={() => handleNavigation(props.onGoToNumberPlates)}
            selected={currentPath === '/client-admin/number-plates'}
          >
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Number Plates" />
          </StyledListItemButton>    
          <StyledListItemButton 
            onClick={() => handleNavigation(props.onGoToQrCodeKeys)}
            selected={currentPath === '/client-admin/qr-code-keys'}
          >
            <ListItemIcon>
              <QrCodeIcon />
            </ListItemIcon>
            <ListItemText primary="QR Code Keys" />
          </StyledListItemButton>
          <StyledListItemButton 
            onClick={() => handleNavigation(props.onGoToPins)}
            selected={currentPath === '/client-admin/pins'}
          >
            <ListItemIcon>
              <PinIcon />
            </ListItemIcon>
            <ListItemText primary="PINs" />
          </StyledListItemButton>
          <StyledListItemButton 
            onClick={() => handleNavigation(props.onGoToMessages)}
            selected={currentPath === '/client-admin/video-messages'}
          >
            <ListItemIcon>
              <Badge
                color="primary"
                badgeContent={unreadCount}
                invisible={unreadCount === 0}
                sx={{
                  '.MuiBadge-badge': {
                    height: '12px',
                    minWidth: '12px',
                    fontSize: '0.4rem',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                  }
                }}
              >
                <VideoChatIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Video Messages" />
          </StyledListItemButton>
          <StyledListItemButton 
            onClick={() => handleNavigation(props.onGoToSnapshots)}
            selected={currentPath === '/client-admin/snapshots'}
          >
            <ListItemIcon>
              <PhotoLibraryIcon />
            </ListItemIcon>
            <ListItemText primary="Snapshots" />
          </StyledListItemButton>
          <StyledListItemButton 
            onClick={() => handleNavigation(props.onGoToMovementVideos)}
            selected={currentPath === '/client-admin/movement-videos'}
          >
            <ListItemIcon>
              <OndemandVideoIcon />
            </ListItemIcon>
            <ListItemText primary="Movement Videos" />
          </StyledListItemButton>
        </>
      )}
    </div>
  );
}

export function SecondaryListItems(props) {
  const { userRole, currentPath } = props;
  return (
  <React.Fragment>
    {userRole.startsWith('CLIENT_') && (
        <>
    <ListSubheader component="div" inset>
      More actions
    </ListSubheader>
    <StyledListItemButton 
      onClick={props.onPreviewKiosk}
      selected={currentPath === '/client-admin/preview-kiosk'}
    >
      <ListItemIcon>
        <PreviewIcon />
      </ListItemIcon>
      <ListItemText primary="Preview Kiosk" />
    </StyledListItemButton>
    <StyledListItemButton 
      onClick={props.onGoToSettings}
      selected={currentPath === '/client-admin/settings'}
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Device Settings" />
    </StyledListItemButton>
    </>
    )}
    </React.Fragment>
  )}

export function GlobalListItems(props) {
    const { userRole, onNavigate, currentPath } = props;

    const handleNavigation = (navigateFunction) => {
        console.log('Navigation attempted, onNavigate:', onNavigate);
        if (onNavigate && !onNavigate()) {
            console.log('Navigation cancelled');
            return;
        }
        console.log('Navigation proceeding');
        navigateFunction();
    };

    return (
        <React.Fragment>
            <ListSubheader component="div" inset>
                Global actions
            </ListSubheader>

            {userRole === 'CLIENT_ADMIN' && (
                <>
                    <StyledListItemButton 
                      onClick={() => handleNavigation(props.onGoToUsers)}
                      selected={currentPath === '/client-admin/users'}
                    >
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                    </StyledListItemButton>
                    <StyledListItemButton 
                      onClick={() => handleNavigation(props.onGoToResponderAliases)}
                      selected={currentPath === '/client-admin/responder-aliases'}
                    >
                        <ListItemIcon>
                            <ContactsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Aliases" />
                    </StyledListItemButton>
                    {!props.user.partnerId && (
                        <StyledListItemButton 
                          onClick={() => handleNavigation(props.onUpgrade)}
                          selected={currentPath === '/client-admin/upgrade'}
                        >
                            <ListItemIcon>
                                <UpgradeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Upgrade" />
                        </StyledListItemButton>
                    )}
                    <StyledListItemButton 
                      onClick={() => handleNavigation(props.onGoToDevelopers)}
                      selected={currentPath === '/client-admin/developers'}
                    >
                      <ListItemIcon>
                        <CodeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Developers" />
                    </StyledListItemButton>
                </>
            )}

            <StyledListItemButton 
              onClick={() => handleNavigation(props.onSignOut)}
              selected={currentPath === '/client-admin/sign-out'}
            >
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
            </StyledListItemButton>
        </React.Fragment>
    );
}