let currentIndex = 0;
let introElementsArray = [];
let activeBox = null;
let activeCallout = null;

const INTRO_COOKIE_NAME = 'hideIntroHighlights';

const setIntroCookie = () => {
    document.cookie = `${INTRO_COOKIE_NAME}=true; path=/; max-age=31536000`; // 1 year expiry
};

const checkIntroCookie = () => {
    return document.cookie.split(';').some(item => item.trim().startsWith(`${INTRO_COOKIE_NAME}=`));
};

const waitForElements = () => {
    return new Promise((resolve) => {
        const checkElements = () => {
            const elements = document.querySelectorAll('[data-intro]');
            // Check if subscription panel is loaded (looking for specific elements)
            const subscriptionPanel = document.querySelector('[data-intro*="subscription"]');
            const loadingSpinner = document.querySelector('.MuiCircularProgress-root');
            
            if (elements.length > 0 && (!subscriptionPanel || !loadingSpinner)) {
                resolve(Array.from(elements));
            } else {
                setTimeout(checkElements, 500); // Check again in 500ms
            }
        };
        
        checkElements();
    });
};

export const createIntroHighlights = async () => {
    try {
        // Check if user has dismissed the intro
        if (checkIntroCookie()) {
            return;
        }

        introElementsArray = await waitForElements();
        if (introElementsArray.length === 0) return;
        
        showHighlight(0);
    } catch (error) {
        console.error('Error initializing highlights:', error);
    }
};

const showHighlight = (index) => {
    // Remove previous highlight if exists
    if (activeBox) activeBox.remove();
    if (activeCallout) activeCallout.remove();
    
    // Remove any existing overlay
    const existingOverlay = document.querySelector('.intro-overlay');
    if (existingOverlay) existingOverlay.remove();
    
    const element = introElementsArray[index];
    const rect = element.getBoundingClientRect();
    
    // Create overlay with transition
    const overlay = document.createElement('div');
    overlay.className = 'intro-overlay';
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0)'; // Start transparent
    overlay.style.backdropFilter = 'blur(0px)'; // Start without blur
    overlay.style.zIndex = '9997';
    overlay.style.transition = 'all 0.3s ease-in-out';
    
    // Only create highlight box and cutout if not the first step
    if (index > 0) {
        // Cut out the highlighted element from the overlay
        const cutout = `
            polygon(
                0% 0%, 
                100% 0%, 
                100% 100%, 
                0% 100%,
                0% ${rect.top}px,
                ${rect.left}px ${rect.top}px,
                ${rect.left}px ${rect.bottom}px,
                ${rect.right}px ${rect.bottom}px,
                ${rect.right}px ${rect.top}px,
                0% ${rect.top}px
            )
        `;
        overlay.style.clipPath = cutout;
        
        // Create highlight box with transition
        const box = document.createElement('div');
        box.className = 'intro-highlight-box';
        box.style.position = 'absolute';
        box.style.border = '2px solid #1976d2';
        box.style.borderRadius = '4px';
        box.style.backgroundColor = 'rgba(25, 118, 210, 0)'; // Start transparent
        box.style.zIndex = '9998';
        box.style.transition = 'all 0.3s ease-in-out';
        
        box.style.top = `${rect.top + window.scrollY}px`;
        box.style.left = `${rect.left + window.scrollX}px`;
        box.style.width = `${rect.width}px`;
        box.style.height = `${rect.height}px`;
        
        document.body.appendChild(box);
        activeBox = box;
        
        // Trigger animation after a small delay
        setTimeout(() => {
            box.style.backgroundColor = 'rgba(25, 118, 210, 0.1)';
        }, 50);
    }
    
    // Create callout with transition
    const callout = document.createElement('div');
    callout.className = 'intro-callout';
    callout.style.position = 'absolute';
    callout.style.backgroundColor = 'white';
    callout.style.padding = '15px';
    callout.style.borderRadius = '4px';
    callout.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
    callout.style.zIndex = '9999';
    callout.style.maxWidth = '300px';
    callout.style.opacity = '0';
    callout.style.transform = 'translateY(10px)';
    callout.style.transition = 'all 0.3s ease-in-out';
    callout.style.cursor = 'move';
    callout.style.userSelect = 'none'; // Prevent text selection
    callout.style.webkitUserSelect = 'none'; // For Safari support
    callout.style.msUserSelect = 'none'; // For IE support
    callout.style.mozUserSelect = 'none'; // For Firefox support

    // Add drag functionality
    let isDragging = false;
    let currentX;
    let currentY;
    let initialX;
    let initialY;

    callout.addEventListener('mousedown', (e) => {
        if (e.target.tagName.toLowerCase() === 'button') return; // Don't drag when clicking buttons
        
        isDragging = true;
        initialX = e.clientX - callout.offsetLeft;
        initialY = e.clientY - callout.offsetTop;
    });

    document.addEventListener('mousemove', (e) => {
        if (!isDragging) return;

        e.preventDefault();
        currentX = e.clientX - initialX;
        currentY = e.clientY - initialY;

        callout.style.left = `${currentX}px`;
        callout.style.top = `${currentY}px`;
    });

    document.addEventListener('mouseup', () => {
        isDragging = false;
    });

    // Add navigation buttons and text
    const content = document.createElement('div');
    content.textContent = element.getAttribute('data-intro');
    
    const buttonContainer = document.createElement('div');
    buttonContainer.style.marginTop = '10px';
    buttonContainer.style.display = 'flex';
    buttonContainer.style.justifyContent = 'space-between';
    buttonContainer.style.flexWrap = 'wrap';
    buttonContainer.style.gap = '10px';
    
    // Add "Don't show again" for first highlight
    if (index === 0) {
        const dontShowButton = document.createElement('button');
        dontShowButton.textContent = "Close";
        dontShowButton.style.fontSize = '0.8em';
        dontShowButton.onclick = () => {
            const confirmDialog = document.createElement('div');
            confirmDialog.style.position = 'fixed';
            confirmDialog.style.top = '50%';
            confirmDialog.style.left = '50%';
            confirmDialog.style.transform = 'translate(-50%, -50%)';
            confirmDialog.style.backgroundColor = 'white';
            confirmDialog.style.padding = '20px';
            confirmDialog.style.borderRadius = '4px';
            confirmDialog.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
            confirmDialog.style.zIndex = '10000';
            confirmDialog.style.minWidth = '300px';
            
            const message = document.createElement('p');
            message.textContent = "You didn't finish the tour. Do you want to do the tour later?";
            message.style.marginBottom = '20px';
            
            const buttonContainer = document.createElement('div');
            buttonContainer.style.display = 'flex';
            buttonContainer.style.justifyContent = 'flex-end';
            buttonContainer.style.gap = '10px';
            
            const cancelButton = document.createElement('button');
            cancelButton.textContent = 'Cancel';
            cancelButton.onclick = () => {
                confirmDialog.remove();
            };
            
            const yesButton = document.createElement('button');
            yesButton.textContent = 'Yes, later';
            yesButton.onclick = () => {
                confirmDialog.remove();
                removeIntroHighlights();
            };
            
            const noButton = document.createElement('button');
            noButton.textContent = 'No, never show again';
            noButton.onclick = () => {
                setIntroCookie();
                confirmDialog.remove();
                removeIntroHighlights();
            };
            
            buttonContainer.appendChild(cancelButton);
            buttonContainer.appendChild(yesButton);
            buttonContainer.appendChild(noButton);
            
            confirmDialog.appendChild(message);
            confirmDialog.appendChild(buttonContainer);
            document.body.appendChild(confirmDialog);
        };
        buttonContainer.appendChild(dontShowButton);
    }
    
    const navigationContainer = document.createElement('div');
    navigationContainer.style.display = 'flex';
    navigationContainer.style.gap = '10px';
    navigationContainer.style.flex = '1';
    
    if (index > 0) {
        const prevButton = document.createElement('button');
        prevButton.textContent = 'Previous';
        prevButton.onclick = () => showHighlight(index - 1);
        navigationContainer.appendChild(prevButton);
    }
    
    if (index < introElementsArray.length - 1) {
        const nextButton = document.createElement('button');
        nextButton.textContent = 'Next';
        nextButton.onclick = () => showHighlight(index + 1);
        navigationContainer.appendChild(nextButton);
    }
    
    buttonContainer.appendChild(navigationContainer);
    
    // Create finish button container (separate from navigation)
    if (index > 0) {
        const finishContainer = document.createElement('div');
        finishContainer.style.display = 'flex';
        finishContainer.style.gap = '10px';
        
        const finishButton = document.createElement('button');
        finishButton.textContent = 'Finish';
        
        if (index < introElementsArray.length - 1) {
            finishButton.onclick = () => {
                const confirmDialog = document.createElement('div');
                confirmDialog.style.position = 'fixed';
                confirmDialog.style.top = '50%';
                confirmDialog.style.left = '50%';
                confirmDialog.style.transform = 'translate(-50%, -50%)';
                confirmDialog.style.backgroundColor = 'white';
                confirmDialog.style.padding = '20px';
                confirmDialog.style.borderRadius = '4px';
                confirmDialog.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
                confirmDialog.style.zIndex = '10000';
                confirmDialog.style.minWidth = '300px';
                
                const message = document.createElement('p');
                message.textContent = "You didn't finish the tour. Do you want to do the tour later?";
                message.style.marginBottom = '20px';
                
                const buttonContainer = document.createElement('div');
                buttonContainer.style.display = 'flex';
                buttonContainer.style.justifyContent = 'flex-end';
                buttonContainer.style.gap = '10px';
                
                const cancelButton = document.createElement('button');
                cancelButton.textContent = 'Cancel';
                cancelButton.onclick = () => {
                    confirmDialog.remove();
                };
                
                const yesButton = document.createElement('button');
                yesButton.textContent = 'Yes, later';
                yesButton.onclick = () => {
                    confirmDialog.remove();
                    removeIntroHighlights();
                };
                
                const noButton = document.createElement('button');
                noButton.textContent = 'No, never show again';
                noButton.onclick = () => {
                    setIntroCookie();
                    confirmDialog.remove();
                    removeIntroHighlights();
                };
                
                buttonContainer.appendChild(cancelButton);
                buttonContainer.appendChild(yesButton);
                buttonContainer.appendChild(noButton);
                
                confirmDialog.appendChild(message);
                confirmDialog.appendChild(buttonContainer);
                document.body.appendChild(confirmDialog);
            };
        } else {
            finishButton.onclick = () => {
                setIntroCookie();
                removeIntroHighlights();
            };
        }
        
        finishContainer.appendChild(finishButton);
        buttonContainer.appendChild(finishContainer);
    }
    
    callout.appendChild(content);
    callout.appendChild(buttonContainer);
    
    // Position callout with viewport bounds checking
    document.body.appendChild(callout); // Add callout to get its dimensions
    const calloutRect = callout.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    let top, left;
    
    // Check if the highlighted element takes up most of the viewport
    const isFullPageHighlight = (rect.width >= viewportWidth * 0.9) && (rect.height >= viewportHeight * 0.9);
    
    if (isFullPageHighlight) {
        // Center the callout in the viewport
        top = window.scrollY + (viewportHeight - calloutRect.height) / 2;
        left = (viewportWidth - calloutRect.width) / 2;
    } else {
        // Calculate initial position (below the element)
        top = rect.bottom + window.scrollY + 10;
        left = rect.left + window.scrollX;
        
        // Check if callout would go below viewport
        if (top + calloutRect.height > window.scrollY + viewportHeight) {
            // Try positioning above the element
            top = rect.top + window.scrollY - calloutRect.height - 10;
            
            // If this would push it off the top of the viewport, position it over the element
            if (top < window.scrollY) {
                top = window.scrollY + 10;
            }
        }
        
        // Check if callout would go off-screen to the right
        if (left + calloutRect.width > viewportWidth) {
            left = viewportWidth - calloutRect.width - 10;
        }
        
        // Ensure callout doesn't go off-screen to the left
        left = Math.max(10, left);
    }
    
    // Apply the calculated position
    callout.style.top = `${top}px`;
    callout.style.left = `${left}px`;
    
    document.body.appendChild(overlay);
    document.body.appendChild(callout);
    
    activeCallout = callout;
    
    // Trigger animations after a small delay
    setTimeout(() => {
        overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        overlay.style.backdropFilter = 'blur(2px)';
        callout.style.opacity = '1';
        callout.style.transform = 'translateY(0)';
    }, 50);
};

export const removeIntroHighlights = () => {
    if (activeBox) activeBox.remove();
    if (activeCallout) activeCallout.remove();
    const overlay = document.querySelector('.intro-overlay');
    if (overlay) overlay.remove();
}; 