import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Button, Checkbox, FormControlLabel, Container, Autocomplete, Grid, Box, Alert, Snackbar, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Divider, useTheme, useMediaQuery, Card, CardContent, Stack } from '@mui/material';
import axios from 'axios';

export const ManageResponders = (props) =>
{
    const [rows, setRows] = useState([]);
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [loadingAddOrUpdate, setLoadingAddOrUpdate] = useState(false);
    const [deletingIds, setDeletingIds] = useState(new Set());
    const [gridLoading, setGridLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [allUsers, setAllUsers] = useState([]); // To hold all available users
    const [selectedUsers, setSelectedUsers] = useState([]); // To hold selected users for the current alias
    const addRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const calculatedHeight = viewportHeight - 340;
        
        setGridHeight(calculatedHeight);
    }, []); 

    useEffect(() => {
        if (!props.tenantId) return; 
        fetchData();
        fetchUsers(); // Fetch users
    }, [props.tenantId]);
        

    useLayoutEffect(() => {
        if(addRef.current) {
            const formHeight = addRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            const calculatedHeight = viewportHeight - formHeight - 350;
            //console.log('HEIGHT: ' + calculatedHeight)  
            setGridHeight(calculatedHeight);
        }
    }, []);

    const fetchUsers = () => {
        axios.get('/api/users', { params: { tenantId: props.tenantId } })
        .then(result => {
          setAllUsers(result.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
      };
      
    
    // State for form inputs
    const [form, setForm] = useState({
        alias: '', users: [], showOnKiosk: true
    });
    
    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };
    

    // Function to handle form submission
    const handleAddSubmit = (e) => {
        e.preventDefault();
        setLoadingAddOrUpdate(true); // Set loading to true
        axios.post('/api/responder-aliases', {...form, users: selectedUsers}, { params: { tenantId: props.tenantId}  })
        .then(data => {
            setLoadingAddOrUpdate(false); // Set loading to false
            // Reset the form
            resetForm();
            // Refresh the list of aliases
            fetchData();
        })
        .catch(error => {
            setLoadingAddOrUpdate(false); // Set loading to false
            setErrorMessage(error.response.data);
            setShowAlert(true);
        });
    };

    const fetchData = () => {
        setGridLoading(true);
        // get the Aliases
        axios.get('/api/responder-aliases', { params: { tenantId: props.tenantId } })
        .then(result => {
            let data = result.data;
            setGridLoading(false);
            setRows(data);
        })
        .catch(error => {
            setGridLoading(false);
            console.error('Error fetching data:', error);
        });
    };

    // State for editing mode and edited entry
    const [isEditing, setIsEditing] = useState(false);
    const [editedEntry, setEditedEntry] = useState(null);

    // Function to handle editing
    const handleEditClick = (entry) => {
        setForm({ alias: entry.alias, showOnKiosk: entry.showOnKiosk });
        setEditedEntry(entry);
        setIsEditing(true);
        

        const associatedUsers = entry.ResponderAliasUsers.map(rau => rau.User); // Extract associated users
        setSelectedUsers(associatedUsers); // Set associated users
  
        
    };

    // Function to handle cancel action
    const handleCancelEdit = () => {
        resetForm();
    };
    
    // Function to handle update submission
    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        setLoadingAddOrUpdate(true); // Set loading to true
    
        // Combine form data and selected users
        const updateData = {
            ...form,
            users: selectedUsers
        };
        axios.put(`/api/responder-aliases/${editedEntry.id}`, updateData, { params: { tenantId: props.tenantId } })
        .then(data => {
            setLoadingAddOrUpdate(false); // Set loading to false
            // Reset the form and editing mode
            resetForm();
            // Refresh the list of aliases
            fetchData();
        })
        .catch(error => {
            setLoadingAddOrUpdate(false); // Set loading to false
            setErrorMessage(error.response.data);
            setShowAlert(true);
        });
    };
    
    const handleDelete = (id) => {
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/responder-aliases/${id}`, { params: { tenantId: props.tenantId } })
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Reset the form and editing mode
            resetForm();
            // Refresh the list of aliases
            fetchData();
        })
        .catch(error => {
            setErrorMessage(error.response.data);
            setShowAlert(true);
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            console.error('Error deleting alias:', error)
        });
    };

    function resetForm()
    {
        setForm({ alias: '', users: [], showOnKiosk: true });
        setSelectedUsers([]);
        setIsEditing(false);
        setEditedEntry(null);
    }

    const columns = [
        // 100% of available space

        { field: "alias", headerName: "Alias", flex: 1},
        {
            field: "ResponderAliasUsers", 
            headerName: "Associated Users", 
            flex: 3,
            renderCell: (params) => (
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    flexWrap: 'nowrap',
                    overflowX: 'auto'
                }}>
                    {params.value.map(rau => (
                        <div 
                            key={rau.User.id} 
                            style={{ 
                                padding: '4px', 
                                border: '1px solid #ccc', 
                                borderRadius: '4px', 
                                margin: '2px'
                            }}
                        >
                            {rau.User.first_name} {rau.User.last_name}
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: "showOnKiosk",
            headerName: "Show on Kiosk",
            flex: 1,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    disabled
                />
            )

        },
        {
            field: "edit",
            headerName: "",
            width: 100,
            renderCell: (params) => (
                <Button onClick={() => handleEditClick(params.row)} variant="contained" color="primary">
                    Edit
                </Button>
            )
        },
        { field: 'delete', headerName: '', width: 100,
        renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id) }
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
        ),
        },
    ];

    return (
        <>
            <Typography style={{marginBottom: 20}}>
                You can specify aliases that either show on the kiosk for the visitor to choose from, or to be used as a group of users that receive visitor notifications (PIN or ANPR). You can then choose which responders (app users) are linked to that alias. For example
                you might create an alias for 'Flat 23A' and then choose two or three users that live at that address to receive alerts when a visitor arrives. Aliases are global to all kiosks.
            </Typography>
            <Divider/>
            
            {/* Form to add alias */}
            <form onSubmit={isEditing ? handleUpdateSubmit : handleAddSubmit} ref={addRef}>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={12} md={2}>
                        <TextField
                            fullWidth
                            label="Alias"
                            name="alias"
                            value={form.alias}
                            onChange={handleInputChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            multiple
                            id="users-autocomplete"
                            options={allUsers}
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                            value={selectedUsers}
                            onChange={(event, newValue) => {
                                setSelectedUsers(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Users"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    name='showOnKiosk' 
                                    checked={form.showOnKiosk} 
                                    onChange={handleInputChange} 
                                />
                            } 
                            label='Show on kiosk'
                        />
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            fullWidth 
                            disabled={loadingAddOrUpdate || !form.alias || selectedUsers.length === 0}
                        >
                            {loadingAddOrUpdate ? <CircularProgress size={24} /> : (isEditing ? 'Update' : 'Add')}
                        </Button>
                    </Grid>
                    {isEditing && (
                        <Grid item xs={12} md={1}>
                            <Button onClick={handleCancelEdit} variant="contained" fullWidth>
                                Cancel
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </form>

            <Box my={4}>
                {isMobile ? (
                    <Stack spacing={2}>
                        {rows.map((row) => (
                            <Card key={row.id}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {row.alias}
                                    </Typography>
                                    
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        Associated Users:
                                    </Typography>
                                    <Box sx={{ mb: 2 }}>
                                        {row.ResponderAliasUsers.map(rau => (
                                            <Box 
                                                key={rau.User.id} 
                                                sx={{ 
                                                    display: 'inline-block',
                                                    m: 0.5,
                                                    p: 1,
                                                    border: '1px solid',
                                                    borderColor: 'divider',
                                                    borderRadius: 1
                                                }}
                                            >
                                                {rau.User.first_name} {rau.User.last_name}
                                            </Box>
                                        ))}
                                    </Box>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={row.showOnKiosk}
                                                disabled
                                            />
                                        }
                                        label="Show on Kiosk"
                                    />
                                    
                                    <Stack 
                                        direction={{ xs: 'column', sm: 'row' }} 
                                        spacing={2} 
                                        sx={{ mt: 2 }}
                                    >
                                        <Button 
                                            onClick={() => handleEditClick(row)} 
                                            variant="contained" 
                                            color="primary"
                                            fullWidth
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            onClick={() => handleDelete(row.id)}
                                            variant="outlined"
                                            color="secondary"
                                            disabled={deletingIds.has(row.id)}
                                            fullWidth
                                        >
                                            {deletingIds.has(row.id) ? <CircularProgress size={24} /> : 'Delete'}
                                        </Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        ))}
                    </Stack>
                ) : (
                    <div style={{ height: gridHeight, width: '100%' }}>
                        <DataGrid 
                            rows={rows} 
                            columns={columns} 
                            loading={gridLoading}
                            pageSize={5} 
                            components={{
                                Header: {
                                    cell: (params) => <strong>{params.value}</strong>
                                }
                            }}
                        />
                    </div>
                )}
            </Box>

            {showAlert && (
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={showAlert}
                autoHideDuration={10000}
                message={errorMessage}
                onClose={() => setShowAlert(false)}
                >
                    <Alert severity="error" onClose={() => setShowAlert(false)}>
                    {errorMessage}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}