import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const movementVideos = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="movement-videos-content"
            id="movement-videos-header"
        >
            <Typography variant="h6">Movement Videos</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                List Movement Videos
            </Typography>
            <CodeBlock code={`GET /api/movement-videos?tenantId=your_tenant_id
{
    "startDate": "2024-03-01",       // Optional: ISO date string
    "endDate": "2024-03-14",         // Optional: ISO date string
    "page": 0,                       // Required: Page number (0-based)
    "pageSize": 10,                  // Required: Number of items per page
    "sortColumn": "id",              // Required: Column to sort by
    "sortDirection": "desc"          // Required: Sort direction ("asc" or "desc")
}

// Response
{
    "count": 150,         // Total number of videos matching criteria
    "rows": [
        {
            "id": 1,
            "createdAt": "2024-03-14T10:30:00.000Z",     // Timestamp of the video
            "cloudinaryUrl_Preview": "https://...",       // URL to the video thumbnail
            "cloudinaryUrl_Video": "https://...",         // URL to the video file
            "isUnread": true                             // Whether the video has been viewed
        }
        // ... more video entries
    ]
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Delete Movement Video
            </Typography>
            <CodeBlock code={`DELETE /api/movement-videos/{id}?tenantId=your_tenant_id

// Response: 200 OK on successful deletion`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Check Movement Videos Setting
            </Typography>
            <CodeBlock code={`GET /api/tenants/{tenantId}?fields=SettingEnableMovementVideo

// Response
{
    "SettingEnableMovementVideo": true    // Whether movement videos are enabled for this tenant
}`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Note: The movement videos endpoint supports server-side pagination and sorting. Videos and previews are stored and served via Cloudinary URLs.
            </Typography>
        </AccordionDetails>
    </Accordion>
); 