import axios from 'axios';

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
  refreshSubscribers.forEach(cb => cb(token));
}

// Axios request interceptor
axios.interceptors.request.use(config => {
  // Your existing logic
  return config;
});

// Axios response interceptor
axios.interceptors.response.use(response => {
  return response;
}, error => {
  const { config, response: { status } } = error;
  const originalRequest = config;

  if (status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;
      axios.post('/api/refreshtoken').then(response => {
        isRefreshing = false;
        onRrefreshed(response.data.token);
        refreshSubscribers = [];
      }).catch(refreshError => {
        console.error('Failed to refresh token', refreshError);
        // Handle failure: redirect to login, etc.
        //alert('Failed to refresh token [' + refreshError + ']');
        window.location.href = '/login';
      });
    }

    const retryOrigReq = new Promise((resolve, reject) => {
      subscribeTokenRefresh(token => {
        // replace the expired token and retry
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        console.log('Retrying original request with new token ' + originalRequest.url)
        resolve(axios(originalRequest));
      });
    });
    return retryOrigReq;
  }

  return Promise.reject(error);
});
