import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Layout from '../../components/Layout';
import { TextField, Button, Checkbox, FormControlLabel, Container, Grid, Box, Dialog, Typography, useTheme, useMediaQuery, Card, CardContent, Stack, Collapse, Alert, AlertTitle } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const MovementVideosGrid = () => {

    // state hooks
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
      });
      const [sortModel, setSortModel] = useState([{
        field: 'id',
        sort: 'desc',
      }]);
      
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false); // New state for loading status
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [deletingIds, setDeletingIds] = useState(new Set());
    const { globalTenantId } = useContext(AuthContext);

    const [filters, setFilters] = useState({
        search: '',
    });
    const [openImage, setOpenImage] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [movementVideosEnabled, setMovementVideosEnabled] = useState(true);
    const filterRef = useRef(null);

    // date range filter
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    // effect hooks
    useEffect(() => {
        if (!globalTenantId) return;
        fetchData();
    }, [paginationModel.page, sortModel[0]?.field, sortModel[0]?.sort, filters, globalTenantId]);

    useEffect(() => {
        if (!globalTenantId) return;
        setPaginationModel(prev => ({
            ...prev,
            page: 0
        }));
        setRows([]);
    }, [globalTenantId]);

    useEffect(() => {
        if (filterRef.current) {
            const formHeight = filterRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            const calculatedHeight = viewportHeight - formHeight - 150;
            
            setGridHeight(calculatedHeight);
        }
    }, []);  // Dependencies can be adjusted as needed
    const fetchData = () => {
        setLoading(true);

        const requestBody = {
            ...filters,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sortColumn: sortModel[0].field,
            sortDirection: sortModel[0].sort
        };

        axios.get(`/api/movement-videos?tenantId=${globalTenantId}`, { params: requestBody })
        .then(result => {
            const data = result.data;
            if (isMobile && paginationModel.page > 0) {
                setRows(prevRows => [...prevRows, ...(data.rows || [])]);
            } else {
                setRows(data.rows || []);
            }
            setRowCount(data.count || 0);
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => {
            setLoading(false);
        });
        axios.get(`/api/tenants/${globalTenantId}`, { params: { fields: ['SettingEnableMovementVideo'] } }).then(result => {
            setMovementVideosEnabled(result.data.SettingEnableMovementVideo);
        }
        ).catch(error => console.error('Error fetching data:', error));
    };

    const handleLoadMore = () => {
        setPaginationModel(prev => ({
            ...prev,
            page: prev.page + 1
        }));
    };

    const handleDelete = (id) => {
        if (!window.confirm("Are you sure you want to delete this item?")) return;
        
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/movement-videos/${id}`, { params: { tenantId: globalTenantId } })
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Refresh the list of pins
            fetchData();
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            console.error('Error deleting movement video:', error)
        });
    }
    const columns = [
        { field: "id", headerName: "ID", width: 90 },
        { field: "createdAt", headerName: "Date/Time", width: 250,
            valueGetter: (params) => {
                const date = new Date(params.value);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                return `${day}/${month}/${year} ${hours}:${minutes}`;
            }
        },
        {
            field: "cloudinaryUrl_Preview",
            headerName: "Preview",
            width: 150,
            renderCell: (params) => {
                if (params.value) {
                    return <img src={params.value} alt="snapshot preview" width="50" onClick={() => handleMediaClick(params.row.cloudinaryUrl_Video)}/>;
                } else {
                    return <div>No preview</div>;
                }
            }
        },        
        { 
            field: "play", 
            headerName: "", 
            width: 100,
            renderCell: (params) => {
                {/* Add the Badge component */}
                
                const isUnread = params.row.isUnread; 
                return (
                            
                <Button variant="contained" color="primary" onClick={() => handleMediaClick(params.row.cloudinaryUrl_Video)}>
                    Play
                </Button>
                );
        }},        { field: 'delete', headerName: '', width: 100, 
        renderCell: (params) => (
            <Button
                onClick={() => handleDelete(params.id)}
                variant="outlined"
                color="secondary"
                disabled={deletingIds.has(params.id)}
            >
                {deletingIds.has(params.id) ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
        ),
        },
    ];


    const handleMediaClick = (src) => {
        setVideoUrl(src);  // Consider renaming this state to `largeMediaSrc`
        setOpenImage(true);  // Consider renaming this state to `openMedia`
    };
    
    const handleCloseImage = () => {
        setOpenImage(false);
    };

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        // Update filters with date range
        setFilters(prev => ({
            ...prev,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
        }));
        setPaginationModel(prev => ({
            ...prev,
            page: 0
        }));
        setRows([]);
    };
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const FilterToggleButton = () => (
        <Box sx={{ 
            display: { xs: 'flex', md: 'none' }, 
            mb: 1,
            px: 1
        }}>
            <Button
                onClick={() => setIsFilterVisible(!isFilterVisible)}
                startIcon={isFilterVisible ? <KeyboardArrowUpIcon /> : <FilterListIcon />}
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                    py: 0.5
                }}
            >
                {isFilterVisible ? 'Hide Filters' : 'Show Filters'}
            </Button>
        </Box>
    );

    return (
        <Layout title='Movement Videos'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {!movementVideosEnabled && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        <AlertTitle>Movement Videos are currently disabled</AlertTitle>
                        Movement videos are disabled for this kiosk. You can enable them in the Settings page and you will be able to view them in this page.
                    </Alert>
                )}

                <FilterToggleButton />
                
                <Collapse in={!isMobile || isFilterVisible}>
                    <form onSubmit={handleFilterSubmit} ref={filterRef}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={6} md={3}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => { setStartDate(newValue); }}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => { setEndDate(newValue); }}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Collapse>

                {isMobile && isFilterVisible && <Box sx={{ mb: 2 }} />}

                <Box my={4}>
                    {isMobile ? (
                        <>
                            <Stack spacing={2}>
                                {rows.map((row) => (
                                    <Card key={row.id}>
                                        <CardContent>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={5}>
                                                    {row.cloudinaryUrl_Preview ? (
                                                        <img 
                                                            src={row.cloudinaryUrl_Preview} 
                                                            alt="video preview" 
                                                            style={{ 
                                                                width: '100%', 
                                                                height: 'auto',
                                                                cursor: 'pointer' 
                                                            }}
                                                            onClick={() => handleMediaClick(row.cloudinaryUrl_Video)}
                                                        />
                                                    ) : (
                                                        <Typography>No preview</Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                                        {new Date(row.createdAt).toLocaleString()}
                                                    </Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                        ID: {row.id}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            
                                            <Stack 
                                                direction={{ xs: 'column', sm: 'row' }} 
                                                spacing={2} 
                                                sx={{ mt: 2 }}
                                            >
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    onClick={() => handleMediaClick(row.cloudinaryUrl_Video)}
                                                    fullWidth
                                                >
                                                    Play
                                                </Button>
                                                <Button
                                                    onClick={() => handleDelete(row.id)}
                                                    variant="outlined"
                                                    color="secondary"
                                                    disabled={deletingIds.has(row.id)}
                                                    fullWidth
                                                >
                                                    {deletingIds.has(row.id) ? <CircularProgress size={24} /> : 'Delete'}
                                                </Button>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Stack>
                            
                            {rows.length > 0 && rows.length < rowCount && (
                                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                                    <Button 
                                        onClick={handleLoadMore}
                                        variant="outlined"
                                        disabled={loading}
                                    >
                                        {loading ? <CircularProgress size={24} /> : 'Load More'}
                                    </Button>
                                </Box>
                            )}
                        </>
                    ) : (
                        <div style={{ height: gridHeight, width: '100%' }}>
                            <DataGrid
                                loading={loading} 
                                rows={rows} 
                                columns={columns} 
                                pageSizeOptions={[10]}
                                paginationMode='server'
                                paginationModel={paginationModel}
                                sortModel={sortModel}
                                onPaginationModelChange={setPaginationModel}
                                onSortModelChange={setSortModel}
                                rowCount={rowCount}
                                sortingOrder={['desc', 'asc']}
                            />
                        </div>
                    )}
                </Box>

                <Dialog 
                    open={openImage} 
                    onClose={handleCloseImage} 
                    fullWidth
                    maxWidth="md"
                >
                    <video 
                        src={videoUrl} 
                        controls 
                        style={{ 
                            width: '100%', 
                            maxHeight: '90vh',
                            objectFit: 'contain' 
                        }} 
                    />
                </Dialog>
            </LocalizationProvider>
        </Layout>
    );
}

export default MovementVideosGrid;
