import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { CircularProgress } from '@mui/material';
import { red } from '@mui/material/colors';
import { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import {Paper} from '@mui/material';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.entrinsic.io/">
        Entrinsic Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignIn() {

    const navigate = useNavigate();  // Initialize the useHistory hook.
    const [showNotVerifiedModal, setShowNotVerifiedModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const location = useLocation();
    const [showPasswordResetAlert, setShowPasswordResetAlert] = useState(false);
    const [showPasswordResetSuccessAlert, setShowPasswordResetSuccessAlert] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false); // This is a boolean state variable that will be used to show a spinner when true

    const { setUser } = useContext(AuthContext);

    const mobileBreakpoint = 600;
    const isMobile = window.innerWidth <= mobileBreakpoint;


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('passwordReset') === 'true') {
            setShowPasswordResetAlert(true);
            setTimeout(() => setShowPasswordResetAlert(false), 10000);  
        }
    }, [location.search]);

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      if (params.get('passwordResetSuccess') === 'true') {
          setShowPasswordResetSuccessAlert(true);
          setTimeout(() => setShowPasswordResetSuccessAlert(false), 10000);  
      }
  }, [location.search]);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoggingIn(true); // Show the spinner

    const data = new FormData(event.currentTarget);

    let email = data.get('email');
    let password = data.get('password');
    try {
      
        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });
        
        // Check if the rate limit has been exceeded
        if (response.status === 429) {
          // Handle rate limit exceeded
          const errorData = await response.json();
          alert(errorData.message); // Show an alert, or you can use a more user-friendly way to display the error
          setLoggingIn(false); // Hide the spinner
          return;
      }

        const data = await response.json();

        if (data.success) {
          // create a user json object
           const user = {
             id: data.id,
             role: data.role,
              email: data.email,
              partnerId: data.partnerId,
              partnerName: data.partnerName,
              is_partner_user: data.is_partner_user
           };

            setUser(user);
            const params = new URLSearchParams(window.location.search);
            let redirectUrl;

            
            if (data.role =='KIOSK')
            {
              redirectUrl = params.get('redirect') || '/kiosk';
              window.location.href = redirectUrl; // this is a bit different, as we are navigating outside the React app
              return;
            }
            
            // otherwise we're still in the React app
            if (data.role=='SUPER_ADMIN')
            {
              redirectUrl = params.get('redirect') || '/admin/customers';
            }
            else {
              redirectUrl = params.get('redirect') || '/client-admin/';
            }
            navigate(decodeURIComponent(redirectUrl));  
    
        } else {
            setLoggingIn(false); // Hide the spinner
            if (data.reason && data.reason === "not_verified") {
              setShowNotVerifiedModal(true);  
              sessionStorage.setItem('verificationToken', data.token);
            } else {
                alert(data.message);
            }
        }
    } catch (error) {
        console.error("Login error:", error);
    }
    setLoggingIn(false); // Hide the spinner
  };

  const handleResend = async () => {
    const token = sessionStorage.getItem('verificationToken');
    
    try {
        const response = await fetch(`/api/notverified?resend=true&token=${token}`);
        if (response.ok) {
            // Maybe show a toast or some feedback saying the email was resent
            setShowAlert(true); // Show the alert
            setTimeout(() => setShowAlert(false), 10000); 
            setShowNotVerifiedModal(false);
        } else {
            console.error('Failed to resend verification email');
        }
    } catch (error) {
        console.error("Error:", error);
    }
};

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <img src='/Entrinsic_Logo.png'/>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          {
            isMobile &&
            (
                <>
                    <Typography variant='body1'>
                        The web portal is not optimised for mobile devices. Please use a desktop, laptop or tablet computer to access the full functionality.
                        If you are looking for the Entrinsic Connect mobile app you can download it from the following link:
                        <div style={{textAlign: 'center', padding: 10}}>
                                <a href='https://apps.apple.com/us/app/entrinsic-connect/id6473429546?platform=iphone'>
                                    <img src='/Appstore.png' alt='App Store' width={150} />
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=io.Entrinsic.Connect&pcampaignid=web_share'>
                                    <img src='/googleplay.png' alt='Google Play' width={150} />
                                </a>
                        </div>
                    </Typography>   
                </>
            ) 
          }

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loggingIn}
            >
              { loggingIn ? <CircularProgress size={24} /> : 'Sign In' }
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/reset-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />

        <Dialog
                open={showNotVerifiedModal}
                onClose={() => setShowNotVerifiedModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Email Not Verified</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot use the system because you have not yet verified your email.
                        Please check your inbox for the verification email.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResend} color="primary">
                        Resend Verification Email
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
                <Alert onClose={() => setShowAlert(false)} severity="success" variant="filled">
                    Verification email was resent!
                </Alert>
            </Snackbar>

            <Snackbar open={showPasswordResetAlert} autoHideDuration={15000}>
                <Alert severity="success">
                    Your password reset link was sent - check your inbox.
                </Alert>
            </Snackbar>

            <Snackbar open={showPasswordResetSuccessAlert} autoHideDuration={15000}>
              <Alert severity="success">
                  Your password has been successfully reset. Please log in with your new password.
              </Alert>
            </Snackbar>

      </Container>
    </ThemeProvider>
  );
}

