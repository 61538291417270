import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Layout from '../../components/Layout';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Button, Checkbox, FormControlLabel, Grid, Box, Dialog, useTheme, useMediaQuery, Card, CardContent, Typography, Stack, Collapse, CircularProgress } from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const LogGrid = () => {

    // state hooks
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
      });
      const [sortModel, setSortModel] = useState([{
        field: 'date',
        sort: 'desc',
      }]);
      
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    
    const [filters, setFilters] = useState({
        search: '',
        excludeKeepalive: false
    });
    const [loading, setLoading] = useState(false); // New state for loading status
    const [openImage, setOpenImage] = useState(false);
    const [largeImageSrc, setLargeImageSrc] = useState(null);
    
    const filterRef = useRef(null);
    const [gridHeight, setGridHeight] = useState(400);  // default value

     // date range filter
     const [startDate, setStartDate] = useState(null);
     const [endDate, setEndDate] = useState(null);

     const { globalTenantId } = useContext(AuthContext);
 
    // effect hooks
    useEffect(() => {
        if (!globalTenantId) return; 
        fetchLogs();
    }, [paginationModel.page, sortModel[0]?.field, sortModel[0]?.sort, filters, globalTenantId]);
    
    useEffect(() => {
        if (!globalTenantId) return;
        setPaginationModel(prev => ({
            ...prev,
            page: 0
        }));
        setRows([]);
    }, [globalTenantId]);
    
    useEffect(() => {
        if (filterRef.current) {
            const formHeight = filterRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            const calculatedHeight = viewportHeight - formHeight - 150;
            
            setGridHeight(calculatedHeight);
        }
    }, []);  // Dependencies can be adjusted as needed
    
    const fetchLogs = () => {
        setLoading(true);

        const requestBody = {
            ...filters,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sortColumn: sortModel[0].field,
            sortDirection: sortModel[0].sort
        };

        axios.post('/api/logs', requestBody, { params: { tenantId: globalTenantId } })
        .then(result => {
            const data = result.data;
            if (isMobile && paginationModel.page > 0) {
                // Create a Set of existing IDs for efficient lookup
                const existingIds = new Set(rows.map(row => row.id));
                // Only add rows that don't already exist
                const newRows = data.rows.filter(row => !existingIds.has(row.id));
                setRows(prevRows => [...prevRows, ...newRows]);
            } else {
                setRows(data.rows || []);
            }
            setRowCount(data.count || 0);
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => {
            setLoading(false);
        });
    };

    const handleImageClick = (src) => {
        setLargeImageSrc(src);
        setOpenImage(true);
    };
    
    const handleCloseImage = () => {
        setOpenImage(false);
    };
    

    const columns = [
        {
          field: "date",
          headerName: "Date/Time",
          flex: 0.2,
          valueGetter: (params) => {
            const date = new Date(params.value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${day}/${month}/${year} ${hours}:${minutes}`;
          },
        },
        {
          field: "event",
          headerName: "Event",
          flex: 0.45,
          renderCell: (params) => (
            params.row.IsImageData === true ?
              <img src={params.value} alt="log" width="50" style={{ cursor: 'pointer' }} onClick={() => handleImageClick(params.value)} /> :
              <span>{params.value}</span>
          ),
        },
        {
          field: "user",
          headerName: "User",
          flex: 0.2,
          valueGetter: (params) => params.row.User?.first_name + ' ' + params.row.User?.last_name
        }
      ];
      

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        // Update filters with date range
        setFilters(prev => ({
            ...prev,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
        }));
        setPaginationModel(prev => ({
            ...prev,
            page: 0
        }));
        setRows([]);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const MobileView = () => (
        <Stack spacing={2}>
            {rows.map((row) => (
                <Card key={row.id}>
                    <CardContent>
                        <Typography variant="subtitle2" color="text.secondary">
                            {formatDate(row.date)}
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            {row.IsImageData ? (
                                <img 
                                    src={row.event} 
                                    alt="log" 
                                    width="100%" 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => handleImageClick(row.event)}
                                />
                            ) : (
                                row.event
                            )}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            {row.User?.first_name} {row.User?.last_name}
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </Stack>
    );

    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const FilterToggleButton = () => (
        <Box sx={{ 
            display: { xs: 'flex', md: 'none' }, 
            mb: 1,
            px: 1
        }}>
            <Button
                onClick={() => setIsFilterVisible(!isFilterVisible)}
                startIcon={isFilterVisible ? <KeyboardArrowUpIcon /> : <FilterListIcon />}
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                    py: 0.5
                }}
            >
                {isFilterVisible ? 'Hide Filters' : 'Show Filters'}
            </Button>
        </Box>
    );

    const handleLoadMore = () => {
        setPaginationModel(prev => ({
            ...prev,
            page: prev.page + 1
        }));
    };

    return (
        <Layout title='Logs'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FilterToggleButton />
                
                <Collapse in={!isMobile || isFilterVisible}>
                    <form onSubmit={handleFilterSubmit} ref={filterRef}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={6} md={3}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => { setStartDate(newValue); }}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => { setEndDate(newValue); }}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Search"
                                    name="search"
                                    value={filters.search}
                                    onChange={handleFilterChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={8} md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="excludeKeepalive"
                                            checked={filters.excludeKeepalive}
                                            onChange={handleFilterChange}
                                        />
                                    }
                                    label="Exclude 'keepalive' events"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={1}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Collapse>

                {isMobile && isFilterVisible && <Box sx={{ mb: 2 }} />}
                
                <Box sx={{ mt: 4 }}>
                    {isMobile ? (
                        <>
                            <Stack spacing={2}>
                                {rows.map((row) => (
                                    <Card key={row.id}>
                                        <CardContent>
                                            <Typography variant="subtitle2" color="text.secondary">
                                                {formatDate(row.date)}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mt: 1 }}>
                                                {row.IsImageData ? (
                                                    <img 
                                                        src={row.event} 
                                                        alt="log" 
                                                        width="100%" 
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={() => handleImageClick(row.event)}
                                                    />
                                                ) : (
                                                    row.event
                                                )}
                                            </Typography>
                                            <Typography variant="body2" sx={{ mt: 1 }}>
                                                {row.User?.first_name} {row.User?.last_name}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Stack>
                            
                            {rows.length > 0 && rows.length < rowCount && (
                                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                                    <Button 
                                        onClick={handleLoadMore}
                                        variant="outlined"
                                        disabled={loading}
                                    >
                                        {loading ? <CircularProgress size={24} /> : 'Load More'}
                                    </Button>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box sx={{ height: gridHeight }}>
                            <DataGrid
                                loading={loading} 
                                rows={rows} 
                                columns={columns}
                                pageSizeOptions={[10]}
                                paginationMode='server'
                                paginationModel={paginationModel}
                                sortModel={sortModel}
                                onPaginationModelChange={setPaginationModel}
                                onSortModelChange={setSortModel}
                                rowCount={rowCount}
                                sortingOrder={['desc', 'asc']}
                                sx={{
                                    '& .MuiDataGrid-cell': {
                                        wordBreak: 'break-word',
                                    },
                                }}
                            />
                        </Box>
                    )}
                </Box>

                <Dialog 
                    open={openImage} 
                    onClose={handleCloseImage} 
                    maxWidth="md" 
                    fullWidth
                    sx={{
                        '& .MuiDialog-paper': {
                            margin: { xs: '16px', sm: '32px' },
                            width: { xs: 'calc(100% - 32px)', sm: 'auto' }
                        }
                    }}
                >
                    <img src={largeImageSrc} alt="Large Log" style={{ width: '100%', height: 'auto' }} />
                </Dialog>
            </LocalizationProvider>
        </Layout>
    );
}

export default LogGrid;
