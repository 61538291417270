import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const kioskStatus = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="kiosk-status-content"
            id="kiosk-status-header"
        >
            <Typography variant="h6">Kiosk Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="body2" paragraph>
                The Kiosk Status API allows you to check the current state of a kiosk, including its connection status, whether a responder is connected, and whether a call is being made. This is particularly useful for monitoring kiosk availability and managing visitor interactions.
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Get Kiosk Status
            </Typography>
            <CodeBlock code={`GET /api/kiosk-status?tenantId=your_tenant_id

// Response when kiosk is idle
{
    "status": {
        "kioskConnected": true,          // Whether the kiosk is online
        "responderConnected": false,     // Whether any responder is connected
        "responderId": null,             // Internal ID of the responder
        "userId": null,                  // UserID of the responder
        "kiosk_calling": null            // null when no call being requested
    }
}

// Response when kiosk is 'calling' (but not answered)
{
    "status": {
        "kioskConnected": true,
        "responderConnected": false,
        "kiosk_calling": {
            "lastUpdated": "2025-01-22T15:45:35.492Z",  // Timestamp of the last status update
            "isDelivery": false,                        // Whether this is a delivery call
            "isNoSpeak": false,                         // Whether this is a no-speak delivery
            "aliasId": 1                                // ID of the responder alias being called
        }
    }
}
// Response when responder is connected (please note this does not necessarily mean a call is in progress)
{
    "status": {
        "kioskConnected": true,
        "responderConnected": true,
        "responderId": "UJk64MRaVvjSBq16AAAH",
        "userId": 1,
        "kiosk_calling": null
    }
}
`} 
/>

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
            Note: The kiosk_calling object is only present when there's a visitor requesting to speak (having pressed Visitor or Delivery). The lastUpdated timestamp can be used to determine how long the call has been waiting to be answered.
            </Typography>
        </AccordionDetails>
    </Accordion>
); 