import React, { useState, useContext, useEffect, useRef } from 'react';
import Layout from '../../components/Layout';
import { 
    Button, 
    Box, 
    Alert, 
    Snackbar, 
    Typography, 
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Card,
    CardContent,
    Grid,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';
import { DEVELOPER_TERMS } from '../../constants/developerTerms';
import ApiDocumentation from '../../components/ApiDocumentation';
import WebHooksDialog from '../../components/Settings/WebHooks';

const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
};


const Developers = () => {
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showCredentialsDialog, setShowCredentialsDialog] = useState(false);
    const [credentials, setCredentials] = useState(null);
    const [developers, setDevelopers] = useState([]);
    const { user } = useContext(AuthContext);
    const [showTerms, setShowTerms] = useState(false);
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const termsContentRef = useRef(null);
    const [webHooksDialogOpen, setWebHooksDialogOpen] = useState(false);

    const fetchDevelopers = async () => {
        try {
            const response = await axios.get('/api/developer-users');
            setDevelopers(response.data);
        } catch (error) {
            setErrorMessage(error.response?.data || 'An error occurred');
            setShowAlert(true);
        }
    };

    useEffect(() => {
        fetchDevelopers();
    }, []);

    const handleCreateDeveloper = async () => {
        setLoading(true);

        try {
            const response = await axios.post('/api/create-developer');
            setCredentials(response.data);
            setShowCredentialsDialog(true);
            await fetchDevelopers();
        } catch (error) {
            setErrorMessage(error.response?.data || 'An error occurred');
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleCloseDialog = () => {
        setShowCredentialsDialog(false);
        setCredentials(null);
    };

    const handleResetPassword = async (developerId) => {
        if (!window.confirm('Are you sure you want to reset this developer user\'s password?')) {
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(`/api/reset-developer-password/${developerId}`);
            setCredentials(response.data);
            setShowCredentialsDialog(true);
        } catch (error) {
            setErrorMessage(error.response?.data || 'An error occurred');
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteDeveloper = async (developerId) => {
        if (!window.confirm('Are you sure you want to delete this developer user?')) {
            return;
        }
        
        setLoading(true);
        try {
            await axios.delete(`/api/delete-developer/${developerId}`);
            await fetchDevelopers();
        } catch (error) {
            setErrorMessage(error.response?.data || 'An error occurred');
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    };

    const developerEmail = developers[0]?.email || 'your_developer_email';

    const handleScroll = (e) => {
        const element = e.target;
        const reachedBottom = Math.abs(
            element.scrollHeight - element.scrollTop - element.clientHeight
        ) < 1;
        
        if (reachedBottom) {
            setHasScrolledToBottom(true);
        }
    };

    return (
        <Layout title="Developers">
            <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Create Developer Account
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                    Create a new developer user with API access. The generated credentials will only be shown once - make sure to save them securely. If you forget your password 
                    you can either reset it or delete the user and create a new one.
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        onClick={() => setShowTerms(true)}
                        disabled={loading || developers.length > 0}
                    >
                        Create Developer User
                    </Button>
                    {developers.length > 0 && (
                        <>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => handleDeleteDeveloper(developers[0].id)}
                                disabled={loading}
                            >
                                Delete Developer User
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => setWebHooksDialogOpen(true)}
                                disabled={loading}
                            >
                                Configure Web Hooks
                            </Button>
                        </>
                    )}
                </Box>

                {developers.length > 0 && (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Existing Developer Account
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Created</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {developers.map((dev) => (
                                        <TableRow key={dev.id}>
                                            <TableCell>{dev.email}</TableCell>
                                            <TableCell>{`${dev.first_name} ${dev.last_name}`}</TableCell>
                                            <TableCell>{formatDate(dev.createdAt)}</TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => handleResetPassword(dev.id)}
                                                    disabled={loading}
                                                >
                                                    Reset Password
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Paper>

           <ApiDocumentation developerEmail={developerEmail} />
            <Dialog 
                open={showCredentialsDialog} 
                onClose={handleCloseDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Developer Credentials Created</DialogTitle>
                <DialogContent>
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        Write down these credentials now. The password cannot be retrieved after closing this dialog.
                    </Alert>
                    {credentials && (
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Email"
                                value={credentials.email}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <IconButton onClick={() => handleCopyToClipboard(credentials.email)}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    ),
                                }}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Password"
                                value={credentials.password}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <IconButton onClick={() => handleCopyToClipboard(credentials.password)}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar 
                open={showAlert} 
                autoHideDuration={6000} 
                onClose={() => setShowAlert(false)}
            >
                <Alert severity="error" onClose={() => setShowAlert(false)}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Dialog
                open={showTerms}
                onClose={() => setShowTerms(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {DEVELOPER_TERMS.title}
                    <Typography variant="caption" display="block">
                        Last Updated: {DEVELOPER_TERMS.lastUpdated}
                    </Typography>
                </DialogTitle>
                <DialogContent 
                    ref={termsContentRef}
                    onScroll={handleScroll}
                    sx={{ 
                        maxHeight: '60vh',
                        overflowY: 'auto'
                    }}
                >
                    {DEVELOPER_TERMS.terms.map((section, index) => (
                        <Box key={index} sx={{ mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                {section.heading}
                            </Typography>
                            {section.clauses.map((clause, clauseIndex) => (
                                <Typography 
                                    key={clauseIndex} 
                                    variant="body2" 
                                    paragraph
                                    sx={{ ml: 2 }}
                                >
                                    {clause}
                                </Typography>
                            ))}
                        </Box>
                    ))}
                    <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                        {DEVELOPER_TERMS.footer}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}>
                        <Typography variant="caption" color="text.secondary">
                            {!hasScrolledToBottom && "Please read the entire document before accepting"}
                        </Typography>
                        <Box>
                            <Button onClick={() => setShowTerms(false)}>
                                Decline
                            </Button>
                            <Button 
                                onClick={() => {
                                    setShowTerms(false);
                                    handleCreateDeveloper();
                                }} 
                                variant="contained"
                                disabled={!hasScrolledToBottom}
                                sx={{ ml: 1 }}
                            >
                                Accept and Create Account
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>

            <WebHooksDialog 
                open={webHooksDialogOpen}
                onClose={() => setWebHooksDialogOpen(false)}
            />
        </Layout>
    );
};

export default Developers; 